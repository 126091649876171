import API from './api.js'

const build = function(config){
  return {
    index: function(params){
      return API.get(config.url + ".json", {
        params: params
      })
    },
    show: function(id, params = {}){
      return new Promise(function(resolve, reject) {
        API.get(
          config.url + "/" + id + '.json', {
            params: params
          }
        ).then(function(response){
          response.data = response.data.data
          resolve(response)
        })
      })
    },
    create: function(params){
      return API.post(
        config.url + ".json", params
      )
    },
    update: function(id, params){
      return API.put(
        config.url + "/" + id + '.json', params
      )
    },
    save: function(params){
      if (params.id){
        return this.update(params.id, params)
      } else {
        return this.create(params)
      }
    },
    destroy: function(id){
      return API.delete(
        config.url + "/" + id + '.json', {}
      )
    },
    action: function(action_name, params = {}) {
      return new Promise(function(resolve, reject) {
        API.post(
          config.url + "/" + action_name + ".json", params
        ).then(function(response){
          resolve(response)
        }).catch(function(error){
          reject(error)
        })
      })
      return
    }
  }
}

const Resources = {
  user: build({ url: '/users'}),
  task: build({ url: '/tasks'}),
  task_kind: build({ url: '/task_kinds'}),
  task_state: build({ url: '/task_states'}),
  order: build({ url: '/orders'}),
  order_position: build({ url: '/order_positions'}),
  order_kind: build({ url: '/order_kinds'}),
  order_state: build({ url: '/order_states'}),
  order_paid_state: build({ url: '/order/paid_states'}),
  datum: build({ url: '/data'}),
  delivery: build({ url: '/deliveries'}),
  payment: build({ url: '/payments'}),
  payment_kind: build({ url: '/payment_kinds'}),
  contact: build({ url: '/contacts'}),
  job: build({ url: '/jobs'}),
  event: build({ url: '/events'}),
  message: build({ url: '/messages'}),
  log: build({ url: '/logs'}),
  conversation: build({ url: '/conversations'}),
  position: build({ url: '/positions'}),
  integration: build({ url: '/integrations'}),
  group: build({ url: '/groups'}),
  role: build({ url: '/roles'}),
  site: build({ url: '/sites'}),
  template: build({ url: '/templates'}),
  trigger: build({ url: '/triggers'}),
  entity: build({ url: '/entities'}),
  item: build({ url: '/items'}),
  notification: build({ url: '/notifications'}),
  notification_configuration: build({ url: '/notification/configurations'}),
  notification_user_configuration: build({ url: '/notification/user_configurations'}),
  document_category: build({ url: '/document/categories'}),
  activity: build({ url: '/activities'}),
  filter: build({ url: '/filters'}),
  procedure: build({ url: '/procedures'}),
  webhook: build({ url: '/webhooks'}),
  contact_role: build({ url: '/contact_roles'}),
  bot: build({ url: '/bot'}),
  widget: build({ url: '/widgets'}),
  dashboard: build({ url: '/dashboards'}),
  report: build({ url: '/reports'}),
  function: build({ url: '/functions'}),
  report_configuration: build({ url: '/report/configurations'}),
  app: build({ url: '/apps'}),
  wiki_space: build({ url: '/wiki/spaces'}),
  wiki_page: build({ url: '/wiki/pages'}),
  username: build({ url: '/usernames'}),
  tag: build({ url: '/tags'})
}

export default Resources
