import Resources from './resources.js'
import Vue from 'vue'

const mixin = {
  created: function(){
    this.fetchItem()
  },
  data: function() {
    return {
      item: null
    }
  },
  computed: {
    modules: function(){
      return this.$store.getters['modules'];
    }
  },
  methods: {
    reload: function(item){
      Vue.set(item, '_reloading', true)
      Resources[this.resourceName].show(item.id).then(function(response){
        self._.each(response.data, function(v,k){
          Vue.set(item, k, v)
        })
        Vue.delete(item, '_reloading')
      })
    },
    save: function(item){
      var self = this
      Vue.delete(item, 'errors')
      Vue.set(item, '_saving', true)
      Resources[this.resourceName].save(item).then(function(response){
        self._.each(response.data.data, function(v,k){
          Vue.set(item, k, v)
        })
        Vue.delete(item, '_saving')
      }).catch(function(response){
        Vue.delete(item, '_saving')
        if (response.data && response.data.errors){
          Vue.set(item, 'errors', response.data.errors)
        }
      })
    },
    beforeInit: function(item){
    },
    fetchItem: function(){
      var self = this
      Resources[this.resourceName].show(this.$route.params.id).then(function(response){
        self.beforeInit(response.data)
        self.item = response.data
      })
    },
  }
}

export default mixin
