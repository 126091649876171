<template>
  <div class="order" style="padding-bottom: 35vh">
    <Header>
      <li class="float-left">
        <a v-scroll-to="{offset: -60, el: '#contact'}">
          <i class="tm-icon zmdi zmdi-account-circle"></i>
          <span class="nav-title">Клиент</span>
        </a>
      </li>
      <li class="float-left">
        <a v-scroll-to="{offset: -60, el: '#order'}">
          <i class="tm-icon zmdi zmdi-assignment"></i>
          <span class="nav-title">Заказ</span>
        </a>
      </li>
      <li class="float-left">
        <a v-scroll-to="{offset: -60, el: '#positions'}">
          <i class="tm-icon zmdi zmdi-shopping-basket"></i>
          <span class="nav-title">Позиции</span>
        </a>
      </li>
      <li class="float-left" v-if="delivery_enabled">
        <a v-scroll-to="{offset: -60, el: '#delivery'}">
          <i class="tm-icon zmdi zmdi-local-shipping"></i>
          <span class="nav-title">Доставка</span>
        </a>
      </li>
      <li class="float-left">
        <a v-scroll-to="{offset: -60, el: '#payments'}">
          <i class="tm-icon zmdi zmdi-money-box"></i>
          <span class="nav-title">Оплата</span>
        </a>
      </li>


      <li class="float-right" v-if="item && item.transitions" >
        <b-dropdown variant="custom" v-if="!item._saving">
          <template slot="button-content">
            <i class="tm-icon zmdi zmdi-arrow-right"></i>
            <span class="nav-title">{{item && item.state && item.state.name}}</span>
          </template>
          <b-dropdown-item v-for="transition in item.transitions" @click="transite(item, transition)">
            {{transition.name}}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <li class="float-right" v-if="item">
        <a
          :disabled="item._saving"
          @click.prevent="save(item)"
        >
          <i class="tm-icon zmdi zmdi-spinner zmdi-hc-spin" v-if="item._saving"></i>
          <i class="tm-icon zmdi zmdi-check-circle" v-else></i>
          <span class="nav-title">Сохранить</span>
        </a>
      </li>

      <li class="float-right" v-if="item && item.contact">
        <a v-bind:href="'tel:'+item.contact.phone">
          <i class="tm-icon zmdi zmdi-phone"></i>
          <span class="nav-title">Позвонить</span>
        </a>
      </li>
    </Header>

    <div class="container" v-if="item">
      <div class="row mb-1">
        <div class="col">
          <div class="contact" id="contact">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-account-circle"></i>
                <span v-if="item && item.contact">{{item.contact.name}}</span>
                <span v-else>Клиент</span>
                <div class="float-right" v-if="item.contact">
                  <a
                    @click="item.contact = null"
                    class="btn btn-sm btn-outline-danger text-danger pointer"
                  >
                    <i class="zmdi zmdi-close"></i>
                    сброс
                  </a>
                </div>
              </h5>
              <ContactForm v-bind:item="item"></ContactForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col">
          <div class="order" id="order">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-assignment"></i>
                {{item.service_id}} от {{item.service_created_at | moment("DD.MM.YYYY")}}
              </h5>
              <OrderForm v-bind:item="item"></OrderForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col">
          <div class="positions" id="positions">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-shopping-basket"></i>
                Позиции
              </h5>
              <PositionsForm v-bind:item="item"></PositionsForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1" v-if="delivery_enabled">
        <div class="col">
          <div class="delivery" id="delivery">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-local-shipping"></i>
                Доставка
              </h5>
              <DeliveryForm v-bind:item="item"></DeliveryForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1" v-if="referrals_enabled">
        <div class="col">
          <div class="payments" id="payments">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-money-box"></i>
                Партнерская программа
              </h5>
              <OrderReferralForm v-bind:item="item"></OrderReferralForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col">
          <div class="payments" id="payments">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-money-box"></i>
                Оплата
              </h5>
              <PaymentsForm v-bind:item="item"></PaymentsForm>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col">
          <FormActions :item="item"></FormActions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemMixin from '../../services/item_mixin'

import ContactForm from './form/contact_form'
import OrderForm from './form/order_form'
import PositionsForm from './form/positions_form'
import DeliveryForm from './form/delivery_form'
import PaymentsForm from './form/payments_form'
import OrderReferralForm from './form/order_referral_form'

export default {
  data: function() {
    return {
      resourceName: 'order'
    }
  },
  mixins: [
    ItemMixin
  ],
  computed: {
    delivery_enabled: function(){
      return this.modules.delivery && this.modules.delivery.enabled
    },
    referrals_enabled: function(){
      return this.modules.referral_system && this.modules.referral_system.enabled
    }
  },
  methods: {
    transite(item, transition){
      item.transition_id = transition.id
      this.save(item)
    }
  },
  components: {
    ContactForm,
    OrderForm,
    PositionsForm,
    DeliveryForm,
    PaymentsForm,
    OrderReferralForm
  }
}
</script>
