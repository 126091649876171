<template>
  <div class="container-fluid mt-1 counts">
    <div class="row">
      <div class="col">
        <swiper :options="swiperOption" >
          <!-- slides -->
          <swiper-slide v-for="item in counts" v-bind:key="item.id">
            <a :class="{'bg-white': !item.selected, 'bg-primary': item.selected}" class="btn btn-block text-center text-dark pointer" @click.prevent="toggle(item)">
              <small class="text-uppercase">{{item.name}}</small><br>
              <span class="badge badge-primary">{{item.metrics.count}}</span> <small>на {{item.metrics.amount_cents | money}}</small>
            </a>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev">
            <i class="zmdi zmdi-chevron-left"></i>
          </div>

          <div class="swiper-button-next" slot="button-next">
            <i class="zmdi zmdi-chevron-right"></i>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  data: function(){
    return {
      swiperOption: {
        slidesPerView: 6,
        slidesPerColumn: 1,
        slidesPerView: 5,
        spaceBetween: 10,
        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      cachedCounts: {}
    }
  },
  methods: {
    toggle: function(item){
      if(!this.$parent.f.in_all_filters){
        this.$parent.f.in_all_filters = []
      }

      if(item.selected){
        var filterItem = this._.find(this.$parent.f.in_all_filters, {id: item.id})
        var index =  this._.indexOf(this.$parent.f.in_all_filters, filterItem)
        this.$parent.f.in_all_filters.splice(index, 1)
      } else {
        this.$parent.f.in_all_filters.push(item)
      }

      this.$parent.search()
    }
  },
  computed: {
    selectedFilters: function(){
      if(this.$parent.f.in_all_filters){
        var n = {}
         this._.each(this.$parent.f.in_all_filters, function(i){
          n[i] = i;
        });
        return n;
      } else {
        return {};
      }
    },
    counts: function(){
      var self = this
      self._.each(this.currentCounts, function(item){
        self.cachedCounts[item.id] = item
      })

      return this._.map(this.currentCounts, function(item){
        if(self.selectedFilters[item.id]){
          self.cachedCounts[item.id].selected = true
        }
        return self.cachedCounts[item.id]
      })
    },
    currentCounts: function(){
      return this.$store.getters["collection/counts"]
    }
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>
