<template>
  <div class="contacts">
    <Header search="true">
      <li class="float-left">
        <router-link to="/orders/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
          <span class="nav-title">Добавить заказ</span>
        </router-link>
      </li>
      <li v-bind:class="{active: filtersEnabled}" class="float-right">
        <a v-on:click="toggleFilters">
          <i class="tm-icon zmdi zmdi-filter-list"></i>
          <span class="nav-title">Открыть фильтры</span>
        </a>
      </li>

      <li class="float-right">
        <b-dropdown variant="custom">
          <template slot="button-content">
            <i
              class="tm-icon zmdi"
              v-bind:class="{
                'zmdi-view-list': isActiveView('Table'),
                'zmdi-view-agenda': isActiveView('Cards'),
                'zmdi-chart': isActiveView('Chart'),
              }"
            ></i>
            <span class="nav-title">Изменить интерфейс</span>
          </template>
          <b-dropdown-item v-on:click="toggleView('Table')">
            <i class="tm-icon zmdi zmdi-view-list"></i> Таблица
          </b-dropdown-item>
          <b-dropdown-item v-on:click="toggleView('Cards')">
            <i class="tm-icon zmdi zmdi-view-agenda"></i> Карточки
          </b-dropdown-item>
        </b-dropdown>
      </li>
      <li class="float-right" v-if="config && config.report_configurations">
        <a v-if="report && report.state == 'requested'">
          <i class="tm-icon zmdi zmdi-spinner zmdi-hc-spin"></i>
          <span class="nav-title">Отчет формируется</span>
        </a>
        <b-dropdown variant="custom" v-else>
          <template slot="button-content">
            <i class="tm-icon zmdi zmdi-cloud-download"></i>
            <span class="nav-title">Скачать отчет</span>
          </template>
          <b-dropdown-item
            v-for="reportConfig in config.report_configurations"
            :key="reportConfig.id"
            @click="makeReport(reportConfig)"
          >
              {{reportConfig.name}}
          </b-dropdown-item>
        </b-dropdown>
      </li>
      <!--
      <li class="float-right">
        <b-dropdown variant="custom">
          <template slot="button-content">
            <i class="tm-icon zmdi zmdi-shopping-cart"></i>
            <span class="nav-title">Архив заказов</span>
          </template>
          <b-dropdown-item>
            <i class="zmdi zmdi-play-circle-outline"></i>
            Активные
          </b-dropdown-item>
          <b-dropdown-item>
            <i class="zmdi zmdi-close-circle-o"></i>
            Закрытые
          </b-dropdown-item>
          <b-dropdown-item>
            <i class="zmdi zmdi-minus-circle-outline"></i>
            Отмененные
          </b-dropdown-item>
        </b-dropdown>
      </li>
    -->
    </Header>
    <NavbarRight>
      <form @submit.prevent="search">
      <div class="card mb-5 pb-5">
        <div class="card-body">

            <br>
            <br>

            <AutocompleteInput
              :multiple="true"
              label="Фильтры"
              attribute="in_any_filters"
              :item="f"
              :resource="'filter'"
              :resource_params="{type_in: ['Filter::OrderFilter']}"
            ></AutocompleteInput>


            <AutocompleteInput
              :multiple="true"
              label="Вид заказа"
              attribute="kind_id_in"
              :item="f"
              :resource="'order_kind'"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Статус заказа"
              attribute="state_id_in"
              :item="f"
              resource="order_state"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Статус оплаты"
              :item="f"
              attribute="paid_state_in"
              resource="paid_state"
            ></AutocompleteInput>

            <PeriodInput
              label="Дата открытия"
              attribute="service_created_at"
              :item="f"
            ></PeriodInput>

            <PeriodInput
              v-if="delivery_enabled"
              label="Дата доставки"
              attribute="delivery_date"
              :item="f"
            ></PeriodInput>

            <AutocompleteInput
              :multiple="true"
              label="Клиент"
              :item="f"
              attribute="contact_id_in"
              resource="contact"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Ответственный"
              :item="f"
              attribute="user_id_in"
              :resource="'user'"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Группа"
              :item="f"
              attribute="group_id_in"
              :resource="'group'"
            ></AutocompleteInput>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-success btn-block btn-lg btn-fixed-bottom"
        >
          <i class="zmdi zmdi-search"></i>
          Найти
        </button>
      </form>
    </NavbarRight>

    <Counts></Counts>

    <Cards v-if="isActiveView('Cards')"></Cards>
    <Table v-if="isActiveView('Table')"></Table>

    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import NavbarRight from '../shared/navbar-right.vue'
import Counts from '../shared/counts'

import FiltersMixin from '../../services/filters_mixin'
import CollectionMixin from '../../services/collection_mixin'
import ViewsMixin from '../../services/views_mixin'


import Table from './views/table'
import Cards from './views/cards'

export default {
  mixins: [
    CollectionMixin,
    FiltersMixin,
    ViewsMixin
  ],
  components: {
    NavbarRight,
    Table,
    Cards,
    Counts
  },
  computed: {
    delivery_enabled: function(){
      return this.modules.delivery && this.modules.delivery.enabled
    }
  },
  data: function(){
    return {
      currentView: 'Table',
      resourceName: 'order',
      filtersData: [
        {
          "g": [
            {
              "m":"and",
              "c":[
                {
                  "a":"kind_id",
                  "p":"in",
                  "v":[]
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>
