<template>
  <div>
    <Header></Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div v-for="item in items" :key="item.id" class="card mb-1 mt-1">
            <router-link :to="{ name: 'template', params: {id: item.id} }">
              <h4 class="card-header">{{item.name}}</h4>
            </router-link>
            <div class="card-body">
              <iframe v-bind:src="templateData(item.body)">
              </iframe>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
  },
  methods: {
    templateData: function(html){
      return 'data:text/html;charset=utf-8,' + encodeURIComponent(html)
    }
  },
  data: function(){
    return {
      resourceName: 'template'
    }
  }
}
</script>

<style scoped>
</style>
