import Vue from 'vue'
import Resources from './resources.js'
import VueScrollTo from 'vue-scrollto'
import Base62 from './base62.js'
import EventBus from './event_bus.js'
import { deserialise } from 'kitsu-core'

const mixin = {
  props: {
    scoped: Object
  },
  data: function(){
    return {
      f: {},
      q: {},
      s: '',
      defaultScope: {},
      limit: 25,
      offset: 0,
      infiniteId: new Date(),
      configuration: {},
      report: {
        state: 'empty'
      }
    }
  },
  created: function () {
    var self = this
    this.clearItems(function(){
      if(!self.skipLoadConfig){
        self.loadConfig()
      }
    })
  },
  computed: {
    config: function(){
      return this.configuration
    },
    items: function(){
      return this.$store.getters["collection/items"];
    },
    counts: function(){
      return this.$store.getters["collection/counts"];
    },
    resource: function(){
      return Resources[this.resourceName]
    },
    lastPageSize: function(){
      return this.$store.getters["collection/lastPageSize"]
    },
    modules: function(){
      return this.$store.getters['modules']
    }
  },
  methods: {
    save: function(item){
      var self = this
      Resources[this.resourceName].save(item).then(function(response){
        self._.each(response.data.data, function(v,k){
          item[k] = v
        })

        EventBus.$emit('notice', {
          text: 'Готово'
        })
      }).catch(function(error){
        EventBus.$emit('notice', {
          variant: 'error',
          text: 'Наши специалисты уже оповещены',
          title: 'Произошла ошибка'
        })

        alert('')
      })
    },
    doAction: function(actionName, params){
      return Resources[this.resourceName].action(actionName, params)
    },
    loadConfig: function(){
      var self = this
      Resources[this.resourceName].show('config').then(function(response){
        self.configuration = response.data
      }).catch(function(response){
        console.error(response)
      })
    },

    setSearchString: function(searchString){
      this.s = searchString;
      this.offset = 0
      this.infiniteId += 1
      this.clearItems();
    },
    search: function(){
      var data = {}
      var self = this

       this._.each(this.f, function(v,k){
        data[k] = self.prepareFilterValue(v,k)
      })
      console.log("filter data:")
      console.log(data)

      setTimeout(function(){ Vue.nextTick(self.setFilters(data)) }, 100)

    },
    prepareFilterValue: function(v, k){
      switch (typeof v) {
        case 'string':
          return v;
        case 'object':
          if(v.length){
            return this._.map(v, function(i){return i.id});
          } else {
            return v
          }
        default:
          debugger
          return v;
      }
    },
    setFilters: function(filtersData){
      this.q = filtersData;
      this.offset = 0
      this.infiniteId += 1
      this.clearItems();
    },
    clearItems: function(callback){
      this.$store.dispatch("collection/clearRecords").then(function(){
        VueScrollTo.scrollTo('#app')
        if(typeof(callback) == 'function'){ callback() }
      })
    },
    afterFirstLoad: function(){},
    appendItems: function(){
      var self = this
      return new Promise((resolve, reject) => {
        self.$store.dispatch('collection/appendRecords', {
          component: self,
          options: Object.assign({
            q: Object.assign(this.q, this.scoped),
            s: this.s,
            limit: this.limit,
            offset: this.offset
          }, this.defaultScope)
        }).then(function(items){
          resolve(items)
        })
      })
    },
    infiniteItems: function($state){
      var self = this
      var firstLoad = self.offset == 0 ? true : false

      this.appendItems().then(function(items){
        if(items && items.length > 0){
          if (items.length == self.limit){
            self.offset = self.offset + items.length
            $state.loaded()
          } else {
            $state.complete()
          }
        } else {
          $state.complete()
        }

        if(firstLoad && typeof(self.afterFirstLoad) == 'function'){
          self.afterFirstLoad()
        }
      }).catch((error) =>{
        console.error(error)
        $state.error()
      })
    },
    makeReport: function(reportConfiguration){
      var self = this
      self.report.state = 'requested'

      Resources.report.create({
        configuration_id: reportConfiguration.id,
        filters: {
          q: Object.assign(this.q, this.scoped),
          s: this.s
        }
      }).then(function(response){
        self.report.state = 'completed'
        self.$notify({
          type: 'success',
          text: 'По мере его готовности Вам придет уведомление',
          duration: 2500,
          title: 'Отчет поставлен в очередь',
          ignoreDuplicates: true,
          closeOnClick: true
        })
      }).catch(function(){
        self.report.state = 'completed' // TODO: hotfix
      })
    }
  }
}

export default mixin
