import Vue from 'vue'

Vue.filter('money', function (value) {
    if (!value) return '0₽'
    value = value.toString()
    return (parseFloat(value) / 100.0).toFixed(2).replace('.00','') + '₽'
  }
)

Vue.filter('weight', function (value) {
    if (!value) return ''
    value = value.toString()
    return (parseFloat(value) / 1000.0).toFixed(2) + 'кг'
  }
)
