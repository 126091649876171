<template>
  <span>
    <b-dropdown variant="custom" size="xs" no-caret>
      <template slot="button-content">
        <span v-if="state"
          class="badge badge-pill badge-light circle text-dark text-uppercase"
          v-bind:style="'background-color:'+(state.color && state.color.replace('0.35', '1'))"
        >
          {{state.name}}
        </span>
        <span class="badge badge-pill badge-light circle text-dark text-uppercase" v-else>
          без статуса
        </span>
      </template>
      <b-dropdown-item
        v-if="transitions && transitions.length > 0"
        v-for="t in transitions"
        :key="t.code"
        @click="transite(item, t)"
      >
        <i
          class="zmdi zmdi-circle"
          style="font-size:50%;line-height:1.15;"
          v-bind:style="'background-color:'+(t.color && t.color.replace('0.35', '1'))"
        ></i>
        {{t.name}}
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
export default {
  name: 'StatesBadge',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Object,
    },
    transitions: {
      type: Array,
      required: true
    },
    item: {}
  },
  methods: {
    transite(item, transition){
      item.transition_id = transition.id
      this.$parent.save(item)
    }
  }
}
</script>
