<template>
  <div class="card">
    <div class="card-body card-padding">
      <form role="form" v-on:submit="signIn()">
        <div class="form-group fg-line has-error">
          <label>Ваш email</label>
          <input class="form-control input-sm" v-model="email" v-on:input="resetInvalid" required="true" placeholder="user@example.com" type="email" />
        </div>
        <div class="form-group fg-line has-error">
          <label>Ваш пароль</label>
          <input class="form-control input-sm" v-model="password" v-on:input="resetInvalid" required="true" placeholder="SuperSecret" type="password" />
        </div>
        <p v-show="invalid" class="alert alert-danger text-center"><small>Некорректный логин и/или пароль</small></p>
        <div class="text-center">
          <button v-bind:disabled="loading" class="btn btn-success btn-block mt-4 waves-effect" type="submit">
            <i class="zmdi zmdi-rotate-right zmdi-hc-spin" v-show="loading"></i>
            <span v-show="!loading">Войти</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import currentUser from '../../services/current_user'

export default {
  created: function(){
    if(this.$store.getters.signed_in){
      this.$router.push({name: 'dashboard'})
    }
  },
  data: function(){
    return {
      email: '',
      password: '',
      invalid: false,
      loading: false
    }
  },
  methods: {
    resetInvalid: function(){
      this.invalid = false
    },
    signIn: function(){
      this.loading = true
      var self = this

      axios.post('/api/v2/oauth/token.json', {
        grant_type: 'password',
        client_id: document.configuration.client_id,
        client_secret: document.configuration.client_secret,
        username: this.email,
        password: this.password
      })
      .then(function (response) {
        currentUser.setSignedIn(response.data, function(){
          if(self.$route.name === 'login'){

            self.$router.push({ name: 'dashboard'})
          } else {
            self.$router.replace()
          }

          setTimeout(function(){
            self.loading = false
          }, 2000)
        })
      })
      .catch(function (error) {
        self.invalid = true
        self.loading = false
      });
    }
  }
}
</script>

<style scoped>
</style>

<i18n>
{
  "ru": {
    "login": "Вход",
    "email": "Email",
    "password": "Пароль",
    "sign_in": "Войти",
    "invalid_login_or_password": "Неправильный логин и/или пароль. Пожалуйста, проверьте логин и пароль и попробуйте еще раз."
  }
}
</i18n>
