<template>
  <div class="container-fluid">
    <Header>
      <li class="float-left">
        <router-link to="/entities/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
        </router-link>
      </li>
    </Header>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h4></h4>
          </div>
          <div class="table-responsive">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items">
                  <td>
                    <router-link :to="{ name: 'entity_items', params: { entity_id: item.id }}">
                      {{item.name}}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <infinite-loading
              @infinite="infiniteItems"
              :identifier="infiniteId"
            ></infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
  },
  data: function(){
    return {
      resourceName: 'entity'
    }
  }
}
</script>
