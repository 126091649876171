<template>
  <div class="usernames" style="margin-top: -35px;">
    <Usernames v-bind:scoped="{contact_id_in: [this.$route.params.id]}"></Usernames>
  </div>
</template>

<script>
import Usernames from '../../usernames/index'

export default {
  components: {
    Usernames
  },
  data: function () {
    return {}
  }
}
</script>
