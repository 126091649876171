<template>
  <div class="order" style="padding-bottom: 35vh">
    <Header>
      <li class="float-right" v-if="item && item.transitions" >
        <b-dropdown variant="custom" v-if="!item._saving">
          <template slot="button-content">
            <i class="tm-icon zmdi zmdi-arrow-right"></i>
            <span class="nav-title">{{item && item.state && item.state.name}}</span>
          </template>
          <b-dropdown-item v-for="transition in item.transitions" @click="transite(item, transition)">
            {{transition.name}}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <li class="float-right" v-if="item">
        <a
          :disabled="item._saving"
          @click.prevent="save(item)"
        >
          <i class="tm-icon zmdi zmdi-spinner zmdi-hc-spin" v-if="item._saving"></i>
          <i class="tm-icon zmdi zmdi-check-circle" v-else></i>
          <span class="nav-title">Сохранить</span>
        </a>
      </li>
    </Header>

    <div class="container-fluid" v-if="item">
      <div class="row mb-1">
        <div class="col">
          <div class="order" id="order">
            <div class="card">
              <h5 class="card-header">
                <i class="tm-icon zmdi zmdi-assignment"></i>
                <span v-if="item.parent && item.parent.id">
                  <router-link :to="'/positions/'+item.parent.id">
                    <span>
                      {{item.parent.name}}
                    </span>
                  </router-link>

                </span>
                <span v-else>
                  <router-link :to="'/positions'">
                    <span>
                      Каталог
                    </span>
                  </router-link>
                </span>

                /

                {{item.name}}
              </h5>
              <div class="card-body">
                <div class="row" v-if="item.id == null">
                  <div class="col col-12">
                    <EnumInput
                      label="тип позиции"
                      :attribute="'selected_component'"
                      :disabled="item.id != null"
                      :item="item"
                      :options="[
                        {id: 'Position::CategoryPosition', component: 'category', name: 'Категория'},
                        {id: 'Position::BundlePosition', component: 'bundle', name: 'Комплект (набор)'},
                        {id: 'Position::ServicePosition', component: 'service', name: 'Услуга'},
                        {id: 'Position::UnitPosition', component: 'unit', name: 'Товар'}
                      ]"
                      :change="selectComponent"
                    ></EnumInput>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-12">
                    <div class="form-group">
                      <label>Название</label>
                      <input type="text" class="form-control" v-model="item.name">
                    </div>

                    <AutocompleteInput
                      :multiple="false"
                      label="Состоит в категории"
                      attribute="parent"
                      :item="item"
                      :resource="'position'"
                      :resource_params="{type_in: ['Position::CategoryPosition']}"
                      :change="selectParent"
                    ></AutocompleteInput>
                  </div>
                </div>

                <div class="row" v-if="item.component">
                  <div class="col col-12">
                    <component :is="item.component" :item="item"></component>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col">
                    <FormActions :item="item"></FormActions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-2" v-if="item && item.component == 'category' && item.id != null">
      <div class="card">
        <h5 class="card-header">
          Позиции в категории
        </h5>
        <div class="card-body">
          <Positions
            :scoped="{'children_of': item.id}"
            :show_header="false"
          ></Positions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Positions from './index.vue'
import Category from './form/category.vue'
import Bundle from './form/bundle.vue'
import Service from './form/service.vue'
import Unit from './form/unit.vue'
import Weight from './form/weight.vue'

import ItemMixin from '../../services/item_mixin'

export default {
  data: function() {
    return {
      resourceName: 'position',
      positionStates: [
        {id: 'active',  name: 'Активна'},
        {id: 'archive', name: 'В архиве'},
      ]
    }
  },
  mixins: [
    ItemMixin
  ],
  computed: {},
  methods: {
    selectState(selected){
      this.item.state = selected.id
    },
    selectParent(selected){
      this.item.parent_id = selected.id
    },
    selectComponent(selected){
      this.item.type = selected.id;
      this.item.component = selected.component;
    },
    transite(item, transition){
      item.transition_id = transition.id
      this.save(item)
    }
  },
  components: {
    Positions,
    Category,
    Bundle,
    Service
  }
}
</script>
