const STATUS_INITIAL = 'INITIAL';
const STATUS_LOADING = 'LOADING';
const STATUS_ERROR = 'ERROR';
const STATUS_SUCCESS = 'SUCCESS';

const parseRecord = function(state, record){
  state.records.push(record)
  //if(record.id == 'f2251bf4-a8d9-4e07-a6b1-b383f93cc47e'){ debugger }
}

const parseCount = function(state, count){
  state.counts.push(count)
}

const fetchRecords = function(config){
  return new Promise((resolve, reject) => {
    config.component.$Progress.start()
    config.component.resource.index(
      config.options
    ).then((response) => {
      config.component.$Progress.finish()
      resolve(response.data)
    }).catch(function(error){
      reject(error)
    })
  })
}

const fetchCounts = function(config){
  var countConfig = {
    component: config.component,
    options: Object.assign({
      serialize_as: 'counts'
    }, config.options)
  }
  return fetchRecords(countConfig)
}

const CollectionStore = {
  namespaced: true,
  state: {
    records: [],
    status: STATUS_INITIAL,
    limit: 25,
    offset: 0,
    counts: []
  },
  mutations: {
    CLEAR_RECORDS: (state, response) => {
      state.records = [];
      state.offset = 0
      state.canLoadMore = true
    },
    APPEND_RECORDS: (state, records) => {
      if(records){
        records.forEach(function(record){
          parseRecord(state, record)
        })
      }
    },
    CLEAR_COUNTS: (state, response) => {
      state.counts = [];
    },
    APPEND_COUNTS: (state, counts) => {
      if(counts){
        counts.forEach(function(count){
          parseCount(state, count)
        })
      }
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    }
  },
  actions: {
    clearRecords ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_RECORDS')
        commit('CLEAR_COUNTS')
        resolve()
      })
    },
    replaceRecords ({ commit, state }, config) {
      return new Promise((resolve, reject) => {
        fetchRecords(config).then((response) => {
          commit('CLEAR_RECORDS')
          commit('APPEND_RECORDS', response.data)
          resolve(response.data)
        })
      })
    },
    appendRecords ({ commit, state }, config) {
      return new Promise((resolve, reject) => {
        fetchRecords(config).then((response) => {
          commit('APPEND_RECORDS', response.data)
          resolve(response.data)
        })

        fetchCounts(config).then((response) => {
          commit('CLEAR_COUNTS')
          commit('APPEND_COUNTS', response.data)
        })
      })
    }
  },
  getters: {
    items: function(state){
      return state.records || []
    },
    counts: function(state){
      return state.counts || []
    },
  }
}

export default CollectionStore
