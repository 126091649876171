<template>
  <li class="list-group-item list-group-item-action pointer" v-bind:class="[node.leaf ? 'is-leaf' : 'is-node']" v-on:click.stop="expandOrClose()">
    <span >
      <i class="zmdi" v-if="!node.leaf" v-bind:class="{'zmdi-minus': node.expanded, 'zmdi-plus': !node.expanded}"></i>
      <span v-once>{{ node.name }}</span>
    </span>
    <ul class="list-group" v-if="node.expanded">
      <tree-node v-for="childNode in flatNodes[node.id]" v-bind:node="childNode" :key="random"></tree-node>
    </ul>
  </li>
</template>

<script>
export default {
  created: function(){
    this.children = this.$parent.flatNodes[this.node.id]
  },
  name: "TreeNode",
  props: {
    node: Object,
  },
  data: function(){
    return {
      random: null,
      children: [],
      flatNodes: this.$parent.flatNodes
    }
  },
  methods: {
    selectNode(node){
      this.$parent.selectNode(node)
    },
    fetchTree(id, scope, callback){
      this.$parent.fetchTree(id, scope, callback)
    },
    expandOrClose() {
      var self = this

      this.selectNode(this.node)

      if (this.node.leaf) {
        return;
      }

      this.fetchTree(this.node.id, 'children_of', function(children){
        self.node.expanded = !self.node.expanded;
        self.children = children
        self.$forceUpdate()
      })
    }
  }
};
</script>
