<template>
  <div>
    <nav
      id="header"
      class="
        navbar
        navbar-expand-lg
        navbar-dark
        bg-primary
      "
      v-bind:class="{ loaded: loaded }"
    >

      <div class="d-block d-hide-md breadcrumbs">
        <Breadcrumbs></Breadcrumbs>
      </div>

      <ul class="top-menu d-none d-md-block">
        <slot></slot>
      </ul>

      <form class="header-search-form form-inline ml-2" v-if="search">
        <i class="tm-icon zmdi zmdi-search searh-icon" style="margin-left:8.5px;position:absolute;"></i>
        <input
          class="form-control form-control-sm mr-sm-2"
          v-model="searchString"
          type="search"
          placeholder="Поиск"
           style="font-family:Arial, FontAwesome;padding-left:20px;"
          v-on:keyup.enter="setSearchString"
        >
      </form>

    </nav>
    <ul v-on:mouseleave="hideMenus"
      id="header-menu"
      class="d-block d-md-none"
      v-bind:class="{'opened': leftMenuOpened}"
    >
      <slot></slot>
    </ul>
    <div id="header-margin"></div>
  </div>
</template>


<script>
import Breadcrumbs from '../../components/shared/breadcrumbs'

export default {
  name: 'Header',
  data: function(){
    return {
      searchString: ''
    }
  },
  components: {
    Breadcrumbs
  },
  props: [
    'search'
  ],
  methods: {
    setSearchString: function(){
      this.$parent.setSearchString(this.searchString)
    },
    hideMenus: function(event){
      if (event.clientY > event.target.scrollHeight){
        this.$store.dispatch('leftMenuToggle', false)
      }
    }
  },
  computed: {
    loaded: function(){
      return this.$store.getters.loaded
    },
    leftMenuOpened: function(){
      return this.$store.getters.leftMenuOpened
    },
    company: function(){
      return this.$store.getters.company
    }
  }
}
</script>

<style scoped>
  .top-menu{
    z-index: 9;
    color: white;
    padding: 2px 0px 2px 6px;
    margin: 0px;
  }
</style>
