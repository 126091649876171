<template>
  <FormGroup :item="item" :label="label" :attribute="attribute">
    <date-picker
      v-model="period"
      mode="date"
      is-range
      is-expanded
      title-position="left"
      color="red"
      @input="setPeriod"
    >
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="row">
          <div class="col-sm-6 mb-1">
            <input
              class="form-control"
              :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
              :value="inputValue.start"
              v-on="inputEvents.start"
            />
          </div>
          <div class="col-sm-6 mb-1">
            <input
              class="form-control"
              :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
              :value="inputValue.end"
              v-on="inputEvents.end"
            />
          </div>
        </div>
      </template>
    </date-picker>
  </FormGroup>
</template>

<script>
export default {
  name: 'PeriodInput',
  components: { },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите период'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    },
    change: {
      type: Function,
      default: function(){}
    },
    nullify: {
      type: Function,
      default: function(){}
    }
  },
  computed: {
    from_attribute(){
      return this.attribute + '_gteq'
    },
    till_attribute(){
      return this.attribute + '_lteq'
    }
  },
  methods: {
    today(emit) {
      const date = [
        this.$moment().startOf('day').utc().toDate(),
        this.$moment().endOf('day').utc().toDate()
      ]
      emit(date);
    },
    yesterday(emit) {
      const date = [
        this.$moment().subtract(1, 'days').startOf('day').utc().toDate(),
        this.$moment().subtract(1, 'days').endOf('day').utc().toDate()
      ]
      emit(date);
    },
    this_week(emit) {
      const date = [
        this.$moment().startOf('week').utc().toDate(),
        this.$moment().endOf('week').utc().toDate()
      ]
      emit(date);
    },
    prev_week(emit) {
      const date = [
        this.$moment().subtract(1, 'week').startOf('week').utc().toDate(),
        this.$moment().subtract(1, 'week').endOf('week').utc().toDate()
      ]
      emit(date);
    },
    this_month(emit) {
      const date = [
        this.$moment().startOf('month').utc().toDate(),
        this.$moment().endOf('month').utc().toDate()
      ]
      emit(date);
    },
    prev_month(emit) {
      const date = [
        this.$moment().subtract(1, 'month').startOf('month').utc().toDate(),
        this.$moment().subtract(1, 'month').endOf('month').utc().toDate()
      ]
      emit(date);
    },

    setPeriod(newRange){
      this.item[this.from_attribute] = this.$moment(newRange.start).format('YYYY-MM-DD')
      this.item[this.till_attribute] = this.$moment(newRange.end).format('YYYY-MM-DD')
    }
  },
  data: function(){
    var self = this
    return {
      period: []
    }
  }
}
</script>
