<template>
  <ol class="breadcrumb text-light">
    <li
      v-for="(breadcrumb, idx) in breadcrumbList"
      :key="idx"
      @click="routeTo(idx)"
      :class="{'linked': !!breadcrumb.link, 'active': true}">
        <span>
          {{ breadcrumb.name }}
        </span>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      breadcrumbList: []
    }
  },
  mounted () { this.updateList() },
  watch: { '$route' () { this.updateList() } },
  methods: {
    routeTo (pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link)
    },
    updateList () { this.breadcrumbList = this.$route.meta.breadcrumb }
  }
}
</script>

<style>
  .breadcrumb{
    color: white;
    padding: 6px 0px 6px 6px;
    margin: 0px;
  }

  .breadcrumb > li{
  }
</style>
