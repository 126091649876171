import NchanSubscriber from 'nchan'
import EventBus from './event_bus.js'

const Subscribe = function (subscription, id){

  if(subscription){
    subscription.stop()
  }

  if(id){
    subscription = new NchanSubscriber(
      'https://ohmyclients.com/sub/' + id
    , {
      subscriber: 'websocket',
      reconnect: 'session',
      shared: true
    });

    subscription.on("message", function(stringData, message_metadata) {
      var message = JSON.parse(stringData)
      console.log(message)
      EventBus.$emit(message['event'], message['data'])
    });

    subscription.start();
  } else {
    subscription = null
  }

  return subscription;
}

export default Subscribe
