<template>
  <div class="">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBlock',
  data: function(){
    return {}
  },
  computed: {},
  props: {
    name: {
      type: String
    }
  }
}
</script>
