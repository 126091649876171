<template>
  <div class="template">
    <Header>
    </Header>

    <div class="container-fluid" v-if="item">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Название шаблона</label>
                <input type="text" class="form-control form-control-sm" v-model="item.name">
              </div>

              <div class="form-group">
                <label>Тема</label>
                <input type="text" class="form-control form-control-sm" v-model="item.subject">
              </div>

              <div class="form-group">
                <label>Макет</label>
                <input type="text" class="form-control form-control-sm" v-model="item.layout_id">
              </div>

              <div class="form-group">
                <label>Код шаблона</label>
                <codemirror
                  v-model="item.body"
                  @ready="onCmReady"
                  @focus="onCmFocus"
                  @input="onCmCodeChange"
                  :options='{"mode": "text/html"}'
                />
              </div>


              <!--<div class="form-group">
                <label>Тело</label>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="item.body"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </div>-->
            </div>
            <div class="card-footer">
              <a class="btn btn-success text-light float-right pointer" v-on:click="save(item)">сохранить</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <iframe v-bind:src="templatePreview" style="width:100%;border: none; height: 80vh;"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemMixin from '../../services/item_mixin'
import Resources from '../../services/resources'

export default {
  created: function(){
  },
  data: function() {
    return {
      resourceName: 'template',
      previewData: ''
    }
  },
  mixins: [
    ItemMixin
  ],
  components: {
  },
  methods: {
    refreshPreview: function(){
      var self = this

        Resources[self.resourceName].action('preview', {
          object_id: null,
          object_type: 'contact',
          layout_id: self.item.layout_id,
          template_body: self.item.body,
          //layout_id: self.item.layout_id
        }).then(function(response){
          self.previewData = response.data.data
        })

    },
    onCmReady(cm) {
      this.refreshPreview()
    },
    onCmFocus(cm) {
      this.refreshPreview()
    },
    onCmCodeChange(newCode) {
      this.refreshPreview()
    },
    onEditorBlur: function(event){
      this.refreshPreview()
    },
    onEditorFocus: function(event){
      this.refreshPreview()
    },
    onEditorReady: function(event){},
  },
  computed: {
    templatePreview: function(){
      return 'data:text/html;charset=utf-8,' + encodeURIComponent(this.previewData)
    }
  }
}
</script>
