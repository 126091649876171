<template>
  <div>
    <Header>
    </Header>
    <div class="container">
      <div class="row">
        <form class="col" v-on:submit="save">
          <div class="card">
            <div class="card-body">
              <div class="form-group row">
                 <label class="col-sm-2 col-form-label">Email</label>
                 <div class="col-sm-10">
                   <input type="text" class="form-control" v-model="user_params.email">
                 </div>
               </div>
               <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Имя</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="user_params.first_name">
                  </div>
                </div>
                <div class="form-group row">
                   <label class="col-sm-2 col-form-label">Фамилия</label>
                   <div class="col-sm-10">
                     <input type="text" class="form-control" v-model="user_params.last_name">
                   </div>
                 </div>
               <div class="form-group row">
                 <label for="inputPassword" class="col-sm-2 col-form-label">Пароль</label>
                 <div class="col-sm-10">
                   <input type="password" class="form-control" id="inputPassword" placeholder="Password">
                 </div>
               </div>

               <div class="form-group row">
                 <div class="col">
                   <button type="submit" class="btn btn-success float-right">Сохранить</button>
                 </div>
               </div>
             </div>
           </div>
         </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../../services/api.js'

export default {
  created: function(){
    this.reloadUserParams()
  },
  components: {
  },
  data: function () {
    return {
      user_params: {}
    }
  },
  methods: {
    reloadUserParams: function(){
      var self = this
      API.get('config.json').then(function(response){
        self.user_params = Object.assign({}, response.data.user)
      })
    },
    save: function(){
      var self = this
      API.user.update('me', this.user_params).then(function(response){
        self.reloadUserParams()
      })
    }
  }
}
</script>

<style scoped>
</style>
