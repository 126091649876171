<template>
  <span>
    <b-button v-b-modal.modal-prevent-closing>Сохранить</b-button>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Сохраните новый фильтр"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Название фильтра"
          label-for="name-input"
          invalid-feedback="это обязательное поле"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </span>
</template>

<script>
import API from '../../services/api.js'

export default {
  data() {
    return {
      name: '',
      nameState: null,
      submittedNames: []
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid ? 'valid' : 'invalid'
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      API.filter.create({
        type: this.config.filters.type,
        name: this.name,
        filters: this.$parent.$parent.q
      })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide()
      })
    }
  },
  computed: {
    config: function(){
      return this.$parent.$parent.config
    }
  }
}
</script>
