<template>
  <span v-if="functions && functions.length > 0">
    <b-dropdown variant="custom" size="xs" no-caret v-if="functions">
      <template slot="button-content">
        <b class="badge badge-pill badge-light circle text-dark text-uppercase">
          &nbsp;<i class="zmdi zmdi-more-vert"></i>&nbsp;
        </b>
      </template>
      <b-dropdown-item
        v-if="functions && functions.length > 0"
        v-for="f in functions"
        :key="f.id"
        @click="run(item, f)"
      >
        {{f.name}}
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
import Resources from '../../services/resources'
import FunctionCallbacks from '../../services/function_callbacks'

export default {
  name: 'FunctionsBadge',
  props: {
    functions: {
      type: Array,
    },
    item: {}
  },
  methods: {
    run(item, f){
      Resources.function.action('run', {
        id: f.id,
        options: f.options
      }).then(function(response){
        if(response.data && response.data.action && response.data.action.type){
          var callback = FunctionCallbacks[response.data.action.type]
          if(callback){
            callback(response.data)
          }
        }

      })
    }
  }
}
</script>
