<template>
  <div v-if="item && item.contact" class="card-body">
    <StringInput
      formGroupComponent="FormGroupRow"
      label="Имя"
      :item="item.contact"
      attribute="first_name"
    ></StringInput>

    <StringInput
      formGroupComponent="FormGroupRow"
      label="Фамилия"
      :item="item.contact"
      attribute="last_name"
    ></StringInput>

    <StringInput
      formGroupComponent="FormGroupRow"
      label="Отчество"
      :item="item.contact"
      attribute="second_name"
    ></StringInput>

    <EmailInput
      formGroupComponent="FormGroupRow"
      label="Email"
      :item="item.contact"
      attribute="email"
    ></EmailInput>

    <PhoneInput
      formGroupComponent="FormGroupRow"
      label="Телефон"
      :item="item.contact"
      attribute="phone"
    ></PhoneInput>
  </div>
  <div v-else class="card-body">
    <AutocompleteInput
      formGroupComponent="FormGroupRow"
      label="Выберите клиента из списка"
      :item="item"
      attribute="contact"
      resource="contact"
    ></AutocompleteInput>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  props: ['item']
}
</script>
