<template>
  <div class="contact-show" v-if="item">
    <Header></Header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">

            <div class="card">
              <div class="card-body">
                <div class="float-right" v-if="item.filters">
                  <span class="badge badge-pill badge-light" v-for="filter in item.filters">
                    {{filter.name}}
                  </span>
                </div>

                <h5>{{item.name}}</h5>
              </div>
            </div>


            <nav class="nav nav-pills nav-fill bg-light">
              <a
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Contact'}"
                v-on:click="item._tab = 'Contact'"
              >
                Основное
              </a>
              <a
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Orders'}"
                v-on:click="item._tab = 'Orders'"
              >
                Заказы
              </a>
              <a
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Tasks'}"
                v-on:click="item._tab = 'Tasks'"
              >
                Задачи
              </a>

              <a
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Messages'}"
                v-on:click="item._tab = 'Messages'"
              >
                Действия
              </a>

              <a v-if="referralSystemEnabled"
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Referral'}"
                v-on:click="item._tab = 'Referral'"
              >
                Партнерская программа
              </a>

              <a v-if="false"
                class="nav-item nav-link pointer"
                v-bind:class="{ active: item._tab === 'Usernames'}"
                v-on:click="item._tab = 'Usernames'"
              >
                Аккаунты
              </a>
            </nav>
          </div>

          <component v-bind:is="item._tab + 'Form'" v-bind:item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemMixin from '../../services/item_mixin'

import ContactForm from './form/contact_form'
import OrdersForm from './form/orders_form'
import TasksForm from './form/tasks_form'
import MessagesForm from './form/messages_form'
import ReferralForm from './form/referral_form'
import UsernamesForm from './form/usernames_form'

export default {
  data: function() {
    return {
      resourceName: 'contact'
    }
  },
  mixins: [
    ItemMixin
  ],
  components: {
    ContactForm,
    OrdersForm,
    TasksForm,
    MessagesForm,
    ReferralForm,
    UsernamesForm
  },
  methods: {
    beforeInit(item){
      if (!item._tab){ item._tab = 'Contact' }
    }
  },
  computed: {
    referralSystem: function(){
      return this.modules.referral_system;
    },
    referralSystemEnabled: function(){
      return this.referralSystem && this.referralSystem.enabled
    }
  }
}
</script>

<style scoped>
</style>
