<template>
  <div>
    <div class="form-group">
      <label>Артикул</label>
        <input type="text" class="form-control" v-model="item.vendor_code">
    </div>

    <div class="form-group">
      <label>Внешний код (service_id)</label>
        <input type="text" class="form-control" v-model="item.service_id">
    </div>

    <EnumInput
      label="Наличие (статус)"
      :attribute="'selected_state'"
      :item="item"
      :options="$parent.positionStates"
      :change="$parent.selectState"
    ></EnumInput>

    <MoneyInput
      label="Цена"
      :attribute="'price_cents'"
      :item="item"
    ></MoneyInput>

    <div class="form-group">
      <label>Описание</label>
      <textarea rows="3" class="form-control" v-model="item.description"></textarea>
    </div>
    <div class="form-group">
      <label>Позиции в наборе</label>
      <div v-for="(bundled_item, index) in item.bundled_items" class="row">
        <div class="col-sm-8">
          <AutocompleteInput
            :multiple="false"
            label="Позиция"
            attribute="position"
            :item="bundled_item"
            :resource="'position'"
            :resource_params="{
              type_not_in: ['Position::CategoryPosition', 'Position::BundlePosition']
            }"
            :change="setBundledPosition(bundled_item)"
          ></AutocompleteInput>
        </div>

        <div class="col-sm-3">
          <StringInput
            label="Количество"
            :attribute="'quantity_cents'"
            :item="bundled_item"
          ></StringInput>
        </div>

        <div class="col-sm-1">
          <div class="form-group pt-4">
            <button class="btn btn-sm btn-danger">
              <i class="zmdi zmdi-close-circle" @click="destroyItem(bundled_item, index)"></i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-sm btn-primary" @click="addItem()">
          Добавить позицию
        </button>
      </div>
    </div>
    <div v-if="item.fields_config && item.fields">
      <hr>
      <div  v-for="field_config in item.fields_config">
        <component
          :is="field_config.component"
          formGroupComponent="FormGroupRow"
          :label="field_config.name"
          :item="item.fields"
          :attribute="field_config.code"
          resource="position"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'item'
  ],
  data: function () {
    return {}
  },
  methods: {
    setBundledPosition(bundled_item){
      return function(selected){
        bundled_item.position_id = selected.id
        bundled_item.name = selected.name
      }
    },
    destroyItem(bundled_item, index){
      this.item.bundled_items.splice(index, 1)
    },
    addItem(){
      this.item.bundled_items.push({
        price_cents: 0,
        quantity_cents: 0
      })
    }
  }
}
</script>
