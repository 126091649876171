<template>
  <div>
    <Header>
      <li class="nav-item">
        <a class="nav-link" v-if="node" v-once>
          {{node.name}}
        </a>
      </li>
    </Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div>
              <tree-node v-for="node in flatNodes[null]" :key="node.id" v-bind:node="node"></tree-node>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card" v-if="node">
            <div class="card-body">
              <div class="form-group">
                <label>Название</label>
                <input type="text" class="form-control" v-model="node.name">
              </div>
              <div class="form-group">
                <label>Описание</label>
                <textarea rows="3" class="form-control" v-model="node.description"></textarea>
              </div>
            </div>
            <div class="card-footer">
              <a v-on:click="save(node)" class="btn btn-success text-light pointer float-right">Сохранить</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
  },
  data: function(){
    return {
      resourceName: 'contact_role'
    }
  }
}
</script>

<style scoped>
</style>
