<template>
  <aside
    id="sidebar"
    v-bind:class="{'toggled': leftMenuOpened}">
    <div class="sidebar-inner c-overflow">
      <ul class="main-menu">
        <!--
        <li v-if="superuser">
          <router-link to="/jobs">
            <i class="zmdi zmdi-gps-dot"></i>
            <span>Маркетинг</span>
          </router-link>
        </li>
      -->
        <li>
          <router-link to="/tasks">
            <i class="zmdi zmdi-check-all"></i>
            <span>Задачи</span>
          </router-link>
        </li>
        <li>
          <router-link to="/orders">
            <i class="zmdi zmdi-mall"></i>
            <span>Заказы</span>
          </router-link>
        </li>
        <li>
          <router-link to="/contacts">
            <i class="zmdi zmdi-accounts"></i>
            <span>Клиенты</span>
          </router-link>
        </li>
        <!--
        <li v-if="superuser">
          <router-link to="/conversations">
            <i class="zmdi zmdi-comments"></i>
            <span>Диалоги</span>
          </router-link>
        </li>

        <li v-if="superuser">
          <router-link to="/wiki">
            <i class="zmdi zmdi-book-image"></i>
            <span>Знания</span>
          </router-link>
        </li>
        -->
        <li>
          <router-link to="/settings">
            <i class="zmdi zmdi-settings"></i>
            <span>Настройки</span>
          </router-link>
        </li>
        <li>
          <router-link to="/notifications" v-bind:class="{'animated': unreadNotificationsCount && unreadNotificationsCount > 0}" class="infinite heartBeat">
            <i class="zmdi zmdi-notifications-active"></i>
            <b v-if="unreadNotificationsCount && unreadNotificationsCount > 0">{{unreadNotificationsCount}}</b>
          </router-link>
        </li>
        <li v-on:click="signOut()">
          <a>
            <i class="zmdi zmdi-power"></i>
            <span>Выход</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import currentUser from '../../services/current_user.js'

export default {
  methods: {
    signOut: function(){
      currentUser.signOut(true)
    }
  },
  computed: {
    superuser: function(){
      return this.$store.getters.super
    },
    leftMenuOpened: function(){
      return this.$store.getters.leftMenuOpened
    },
    unreadNotificationsCount: function(){
      return this.$store.getters.unreadNotificationsCount
    }
  }
}
</script>
