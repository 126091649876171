<template>
  <div class="register">
    <p>register</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>
