<template>
  <div class="logs">
    <Logs :scoped="{contact_id_in: [this.$route.params.id]}"></Logs>
  </div>
</template>

<script>
import Logs from '../../logs/index'

export default {
  data: function () {
    return {}
  },
  components: {
    Logs
  }
}
</script>

<style scoped>
</style>
