<template>
  <div>
    <Header search="true">
      <li class="float-left">
        <router-link to="/conversations/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
        </router-link>
      </li>
      <li v-bind:class="{active: filtersEnabled}" class="float-right">
        <a v-on:click="toggleFilters">
          <i class="tm-icon zmdi zmdi-filter-list"></i>
          <span class="nav-title">Открыть фильтры</span>
        </a>
      </li>
      <li class="float-right">
        <a>
          <i class="tm-icon zmdi zmdi-alert-circle"></i>
          <span class="nav-title">Приоритеты</span>
        </a>
      </li>
      <li class="float-right">
        <a>
          <i class="tm-icon zmdi zmdi-notifications-active"></i>
          <span class="nav-title">Непрочитанные</span>
        </a>
      </li>
      <li class="float-right">
        <a>
          <i class="tm-icon zmdi zmdi-mail-reply"></i>
          <span class="nav-title">Неовеченные</span>
        </a>
      </li>
    </Header>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="listview lv-bordered">
              <div class="lv-header-alt clearfix">
                <h2 class="lvh-label hidden-xs">Some text here</h2>
                <div class="lvh-search">
                  <input type="text" placeholder="Начните печатать для поиска..." class="lvhs-input">
                  <i class="lvh-search-close">&times;</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-3">
          <div class="card conversation" v-for="item in items">
            <router-link :to="'conversations/'+item.id" tag="div" class="conversation-inner">
              <PriorityBadge v-bind:item="item" class="float-right"></PriorityBadge>

              <span class="float-right text-sm" v-bind:title="item.updated_at">
                <i class="zmdi zmdi-time"></i>
                {{item.updated_at | moment("from", "now") }}
              </span>
              <div class="lv-avatar bg-success float-left">a</div>

              <div><b class="text-uppercase">{{item.contact.name}}</b></div>
              <span>{{item.name}}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import Filters from '../shared/filters'

import FiltersMixin from '../../services/filters_mixin'
import CollectionMixin from '../../services/collection_mixin'

export default {

  mixins: [
    FiltersMixin,
    CollectionMixin
  ],
  components: {
    Filters
  },
  data: function () {
    return {
      resourceName: 'conversation'
    }
  }
}
</script>

<style scoped>
</style>
