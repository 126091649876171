<template>
  <div
    class="d-flex"
    :class="{
      'justify-content-start': payload.direction == 'incoming',
      'justify-content-end': payload.direction != 'incoming'
    }"
  >
    <div class="msg">

      <div class="body" >
        <b class="subject" v-html="payload.subject"></b>
        <div v-html="payload.body"></div>
        <div class="text-right">
          <small class="text-muted" :title="payload.created_at">
            <i class="zmdi" :class="payload.icon"></i>
            {{payload.created_at | moment("from", "now", true) }} назад
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object }
  },
  computed: {
    payload: function(){
      if (this.item && this.item.payload){
        return this.item.payload
      } else {
        return {}
      }
    }
  }
}
</script>
