const mixin = {
  data: function(){
    return {
      rerenderCount: 0
    }
  },
  computed: {
    positionsObject(){
      var value = {}
      this._.each(this.item.order_positions, function(op){
        value[[op.position_id, op.price_cents, op.bundle_position_id]] = op
      })
      return value
    }
  },
  methods: {
    addPositionToOrder(position){
      var obj = this.positionsObject
      var op = obj[[position.id, position.price_cents, null]]

      if(op){
        this.addQuantityForOrderPosition(op, position.quantity_cents)
      } else {
        this.initOrderPositionFromPosition(position)
      }
    },
    removeOrderPositionFromOrder(op){
      op._destroy = true
      this.rerender()
    },
    removeAllOrderPositions(){
      var self = this
      this._.each(this.item.order_positions, function(op){
        self.removeOrderPositionFromOrder(op)
      })
    },
    changeQuantityForOrderPosition(op, quantity){
      op.quantity_cents = op.quantity_cents + quantity

      if(op.quantity_cents <= 0){
        this.removeOrderPositionFromOrder(op)
      }
    },
    addQuantityForOrderPosition(op, quantity){
      this.changeQuantityForOrderPosition(op, quantity)
    },
    removeQuantityForOrderPosition(op, quantity){
      this.changeQuantityForOrderPosition(op, -quantity)
    },
    initOrderPositionFromPosition(position){
      var op = {
        name: position.name,
        price_cents: position.price_cents,
        weight_cents: position.weight_cents,
        amount_cents: position.amount_cents || position.price_cents * position.quantity_cents,
        quantity_cents: position.quantity_cents,
        position_id: position.id,
        position: position
      }

      this.item.order_positions.push(op)
    },
    updateOrderPositionName(op){
      if(!op.editable_name){ return }
      var value = prompt("Измените название позиции", op.name)
      op.name = value
    },
    updateOrderPositionWeight(op){
      if(!op.editable_weight_cents){ return }
      var value = prompt("Измените вес позиции", (op.weight_cents / 1000.0).toFixed(3))
      op.weight_cents = (parseFloat(value) * 1000.0).toFixed(0)
    },
    rerender(){
      this.rerenderCount += 1
    }
  }
}

export default mixin
