<template>
  <div>
    <Header>
    </Header>
    <div class="container">
      <div class="row">
        <form class="col" >
          <div class="card">
            <div class="card-body">
              <h6>Настройте внешний вид системы</h6>
              <p>Выберите подходящий фон</p>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="col-sm-3 col-6 mt-3 pointer" v-for="(bg,index) in backgrounds">
          <img :src="bg" @click="selectBg(bg, index)" class="w-100">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import backgrounds from '../../services/backgrounds'
import currentUser from '../../services/current_user'
import Resources from '../../services/resources'

export default {
  data: function(){
    return {
      backgrounds: backgrounds
    }
  },
  methods: {
    selectBg(bg, index){
      Resources.user.update(
        this.user.id,
        {
          bg: index + 1
        }
      ).then(function(){
        currentUser.reloadConfig()
      })
    }
  },
  computed: {
    user: function(){
      return this.$store.getters.user
    }
  }
}
</script>
