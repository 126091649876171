<template>
  <form v-on:submit="become" class="container pt-5">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" v-model="email">
        </div>

        <button type="submit" class="btn btn-primary">Стать</button>
      </div>
    </div>
  </form>
</template>

<script>
import Resources from '../../services/resources.js'
import currentUser from '../../services/current_user.js'

export default {
  data: function () {
    return {
      email: ''
    }
  },
  methods: {
    become: function () {
      Resources.user.action('become', {
        email: this.email
      }).then(function(response){
        currentUser.setSignedIn(response.data)
        document.location.reload
      }).catch(function(error){
        switch (error.status) {
          case 404: alert("пользователь не найден");break;
          case 403: alert("нет доступа");break;
          case 422: alert("нельзя выполнить действие");break;
          default:

        }
      })
    }
  }
}
</script>

<style scoped>
</style>
