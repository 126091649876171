<template>
  <div class="container-fluid">
    <Header>
      <li class="float-left">
        <router-link to="/items/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
        </router-link>
      </li>
    </Header>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5>{{entity.name}}</h5>
          </div>
          <div class="table-responsive" v-if="entity">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th v-for="f in entity.fields">{{f.name}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items">
                  <td v-for="f in entity.fields">
                    <input class="form-control" type="text" v-model="item.fields[f.code]">
                  </td>
                </tr>
              </tbody>
            </table>
            <infinite-loading
              @infinite="infiniteItems"
              :identifier="infiniteId"
            ></infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'
import Resources from '../../services/resources'

export default {
  created: function(){
    var self = this
    Resources.entity.show(this.$route.params.entity_id).then(function(response){

      self.entity = response.data
    })
  },
  mixins: [CollectionMixin],
  components: {
  },
  data: function(){
    return {
      entity: null,
      resourceName: 'item',
      defaultScope: {
        entity_id: this.$route.params.entity_id
      }
    }
  }
}
</script>

<style scoped>
</style>
