/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb



//import 'material-design-iconic-font/scss/node_modules/material-design-iconic-font/scss/material-design-iconic-font.scss'

import "@babel/polyfill";
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import BootstrapVue from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import Lodash from 'lodash'
import VueLodash from 'vue-lodash'
import Multiselect from 'vue-multiselect'
import VueProgressBar from 'vue-progressbar'
import InfiniteLoading from 'vue-infinite-loading'
import momentLocaleRu from 'moment/locale/ru'
import moment from 'moment'
import VueMoment from 'vue-moment'
import VueScrollTo from 'vue-scrollto'
import VueMask from 'v-mask'
import EventBus from '../src/services/event_bus'
import VueTextareaAutosize from 'vue-textarea-autosize'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import ToggleButton from 'vue-js-toggle-button'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'

import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/ruby/ruby.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import '../src/elements'
import '../src/services/filters'

import store from '../src/services/store'
import router from '../src/services/router'
import App from '../src/components/app.vue'
import '../src/favicons.js'

Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(PortalVue) // for b-toast
Vue.use(VueLodash, { lodash: Lodash })
Vue.use(VueScrollTo)
Vue.use(VueMask)
Vue.use(VueTextareaAutosize)
Vue.use(Notifications)
Vue.use(ToggleButton)
Vue.use(VModal)
Vue.use(VueMoment, {
  moment
})

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '1px'
})

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'default',
    distance: 250
  },
  system: {
    throttleLimit: 250,
  },
  slots: {
    noMore: 'Все доступные данные загружены.',
    noResults: 'Все доступные данные загружены.',
  }
});

Vue.use(VueCodemirror, {
  options: {
    tabSize: 2,
    mode: 'text/x-ruby',
    theme: 'material',
    lineNumbers: true,
    line: true
  }
})

Vue.use(VueQuillEditor, /* { default global options } */)

Chartkick.options = {
  colors: [
    '#9CCC65',
    '#66BB6A',
    '#26A69A',
    '#26C6DA',
    '#29B6F6',
    '#42A5F5',
    '#FFEE58',
    '#FFCA28',
    '#FFA726',
    '#FF7043',
    '#BDBDBD',
    '#78909C',
    '#8BC34A',
    '#FF5722',
    '#FFC107',
    '#009688',
    '#607D8B',
    '#673AB7'
  ]
}

Chart.defaults.global.legend.labels.usePointStyle = true
Chart.defaults.global.legend.labels.boxWidth = 20
Chart.defaults.global.legend.labels.fontSize = 11

Vue.use(Chartkick.use(Chart))


Vue.component('multiselect', Multiselect)

Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)


const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    'login': 'Вход'
  }
})

Vue.prototype.$event_bus = EventBus

Vue.moment().locale('ru')

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
    localStorage: {
      accessToken: {
        type: Object,
        default: {}
      }
    }
  }).$mount('#app')
  document.body.className += ' loaded'
  document.getElementById('loading').className += ' loaded'
})
