<template>
  <div class="card-body" v-if="item">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Дата</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" v-model="item.delivery.date">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Время</label>
      <div class="btn-group pl-3">
        <button
          type="button"
          class="btn btn-sm pl-3 pr-3"
          :class="{
            'btn-primary': inDeliveryPeriod(hour),
            'btn-light': !inDeliveryPeriod(hour)
          }"
          @click="setDeliveryTime(hour)"
          v-for="hour in delivery_hours"
        >
          {{hour}}
        </button>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Город</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" v-model="item.delivery.city">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Адрес</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" v-model="item.delivery.address">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Комментарий</label>
      <div class="col-sm-10">
        <textarea rows="3" class="form-control" v-model="item.delivery.description">
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryForm',
  props: ['item'],
  methods: {
    inDeliveryPeriod: function(hour){
      return(
        this.time_from &&
        this.time_till &&
        this.time_from <= hour &&
        this.time_till >= hour
      )
    },
    setDeliveryTime: function(hour){
      if(this.item.delivery){
        var from = this.item.delivery.time_from
        var till = this.item.delivery.time_till

        if(from && till){
          from = hour
          till = null
        } else if (from && !till) {
          till = hour
        } else if (!from && till) {
          from = hour
          till = null
        } else {
          from = hour
          till = null
        }

        if (from && till && from > till){
          [from, till] = [till, from];
        }

        this.item.delivery.time_from = from
        this.item.delivery.time_till = till
      }


/*
      $ctrl.setDeliveryTime = (item, hour) ->
        if item.delivery?
          if item.delivery.time_from?
            if item.delivery?.time_till?
              item.delivery.time_till = null
              item.delivery.time_from = hour
            else
              if item.delivery.time_from > hour
                item.delivery.time_till = item.delivery.time_from
                item.delivery.time_from = hour
              else
                item.delivery.time_till = hour

              delete item.delivery_time_picker
          else
            item.delivery.time_from = hour
        else
          item.delivery = {}
          item.delivery.time_from = hour
*/

    }
  },
  computed: {
    delivery(){
      return this.$parent.modules.delivery;
    },
    time_from(){
      return this.item.delivery.time_from;
    },
    time_till(){
      return this.item.delivery.time_till;
    },
    delivery_hours(){
      var hours = {
        from: this.delivery.settings.delivery_from_hour,
        till: this.delivery.settings.delivery_till_hour
      }

      var ary = []; var i = hours.from

      while (i <= hours.till) {
        ary.push(i)
        i+=1
      }

      return ary;
    }
  }
}
</script>
