<template>
  <div>
    <ul>
      <li v-for="item in actions" :key="item.id">{{item.type_name}}: {{item.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    'actions'
  ]
}
</script>
