<template>
  <div class="tasks" style="margin-top: -35px;">
    <Tasks v-bind:scoped="{contact_id_in: [this.$route.params.id]}"></Tasks>
  </div>
</template>

<script>
import Tasks from '../../tasks/index'

export default {
  components: {
    Tasks
  },
  data: function () {
    return {}
  }
}
</script>

<style scoped>
</style>
