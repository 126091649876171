<template>
  <div class="container-fluid">
    <div class="card">
      <form class="card-body" autocomplete="off" @submit="save(item)">
        <div class="row">
          <div class="col-sm-6">
            <FormBlock>
              <StringInput
                label="Имя"
                :item="item"
                attribute="first_name"
              ></StringInput>

              <StringInput
                label="Фамилия"
                :item="item"
                attribute="last_name"
              ></StringInput>

              <StringInput
                label="Отчество"
                :item="item"
                attribute="second_name"
              ></StringInput>
            </FormBlock>
          </div>
          <div class="col-sm-6">
            <FormBlock>
              <EmailInput
                label="Email"
                :item="item"
                attribute="email"
              ></EmailInput>

              <PhoneInput
                label="Телефон"
                :item="item"
                attribute="phone"
              ></PhoneInput>

              <TagsInput
                label="Теги"
                :item="item"
                attribute="tags"
                resource="contact"
              ></TagsInput>

              <StringInput
                label="Код"
                :item="item"
                attribute="service_id"
              ></StringInput>
            </FormBlock>
          </div>
          <div class="col-sm-12">
            <FormBlock>
              <TextareaInput
                label="Пометки"
                :rows="2"
                :item="item"
                attribute="description"
              ></TextareaInput>
            </FormBlock>
          </div>
        </div>
        <hr>
        <div class="row" v-if="item.fields_config && item.fields">
          <div v-bind:key="field_config.code" class="col" v-for="field_config in item.fields_config">
            <component
              :is="field_config.component"
              :componentParams="field_config.component_params"
              formGroupComponent="FormGroup"
              :label="field_config.name"
              :item="item.fields"
              :multiple="field_config.multiple"
              :attribute="field_config.code"
              :resource="field_config.component_params.resource"
            ></component>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <FormActions :item="item"></FormActions>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: function () {
    return {}
  },
  methods: {
    reload(item){
      return this.$parent.reload(item);
    },
    save(item){
      return this.$parent.save(item);
    }
  }
}
</script>

<style scoped>
</style>
