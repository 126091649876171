<template>
  <div>
    <Header></Header>
    <div class="container-fluid" style="padding-bottom: 35vh" v-if="item">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <form v-on:submit="save(item)">
                <div class="row">
                  <div class="col-sm-8">
                    <TextareaInput
                      :label="'Название'"
                      :attribute="'name'"
                      :item="item"
                    ></TextareaInput>

                    <PriorityInput
                      label="Приоритет"
                      attribute="priority"
                      :item="item"
                    ></PriorityInput>

                    <TagsInput
                      label="Теги"
                      :item="item"
                      attribute="tags"
                      resource="task"
                    >
                    </TagsInput>

                    <TextareaInput
                      :rows="4"
                      label="Описание"
                      :item="item"
                      attribute="description"
                    ></TextareaInput>

                    <div v-if="item.fields_config && item.fields">
                      <hr>
                      <div  v-for="field_config in item.fields_config">
                        <component
                          :is="field_config.component"
                          formGroupComponent="FormGroupRow"
                          :label="field_config.name"
                          :item="item.fields"
                          :attribute="field_config.code"
                          resource="task"
                        ></component>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <EnumInput
                      label="Вид"
                      :options="kinds"
                      attribute="kind"
                      :item="item"
                    ></EnumInput>

                    <AutocompleteInput
                      label="<i class='zmdi zmdi-account'></i> Клиент"
                      resource="contact"
                      :attribute="'contact'"
                      :item="item"
                      :change="clearContactRelatedFields"
                      :nullify="clearContactRelatedFields"
                    ></AutocompleteInput>

                    <AutocompleteInput
                      label="<i class='zmdi zmdi-shopping-basket'></i> Заказ"
                      resource="order"
                      :attribute="'order'"
                      :item="item"
                      :change="clearOrderRelatedFields"
                      :nullify="clearOrderRelatedFields"
                    ></AutocompleteInput>

                    <AutocompleteInput
                      label="Позиция"
                      resource="order_position"
                      :attribute="'order_position'"
                      :item="item"
                    ></AutocompleteInput>

                    <AutocompleteInput
                      label="Ответственный"
                      resource="user"
                      :attribute="'user'"
                      :item="item"
                    ></AutocompleteInput>
                  </div>
                </div>
                <FormActions :item="item"></FormActions>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ItemMixin from '../../services/item_mixin'

export default {
  data: function() {
    return {
      resourceName: 'task'
    }
  },
  methods: {
    clearContactRelatedFields(){
      this.deleteRelationship(this.item, 'order')
      this.clearOrderRelatedFields()
    },
    clearOrderRelatedFields(){
      this.deleteRelationship(this.item, 'order_position')
    },
    deleteRelationship(item, relationship){
      Vue.delete(item, relationship)
    }
  },
  computed: {
    kinds: function(){
      return this.$store.getters['modules'].tasks.settings.kind;
    },
    orderParams: function(){
      return {
        q: { contact_id_in: [this.item.contact.id] }
      }
    },
    orderPositionParams: function(){
      return {
        q: { order_id_in: [this.item.order.id], services: true }
      }
    }
  },
  mixins: [
    ItemMixin
  ]
}
</script>

<style scoped>
</style>
