<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div v-for="item in items" class="card text-center mb-1 mt-1">
          <div class="card-header" v-bind:style="{'background-color': item.state && item.state.color}">
            <div class="d-block d-md-none">
              <b class="text-left">{{item.contact.name}}</b>
            </div>
            <ul class="nav nav-tabs card-header-tabs">
              <li class="nav-item pointer" v-on:click="setTab(item,'Contact')">
                <a class="nav-link" v-bind:class="{'active': item._tab == 'Contact'}" >
                  <i class="tm-icon zmdi zmdi-account-circle"></i>
                  <span class="d-none d-md-inline-block">{{item.contact.name}}</span>
                </a>
              </li>
              <li class="nav-item pointer" v-on:click="setTab(item,'Order')">
                <a class="nav-link" v-bind:class="{'active': item._tab == 'Order'}" >
                  <i class="tm-icon zmdi zmdi-assignment"></i>
                  <span class="d-none d-md-inline-block">{{item.service_id}}</span>
                </a>
              </li>
              <li v-if="delivery_enabled" class="nav-item pointer" v-on:click="setTab(item,'Delivery')">
                <a class="nav-link" v-bind:class="{'active': item._tab == 'Delivery'}">
                  <i class="tm-icon zmdi zmdi-local-shipping"></i>
                </a>
              </li>
              <li class="nav-item pointer" v-on:click="setTab(item,'Positions')">
                <a class="nav-link" v-bind:class="{'active': item._tab == 'Positions'}">
                  <i class="tm-icon zmdi zmdi-shopping-basket"></i>
                </a>
              </li>
              <li class="nav-item pointer" v-on:click="setTab(item,'Payments')">
                <a class="nav-link" v-bind:class="{'active': item._tab == 'Payments'}">
                  <i class="tm-icon zmdi zmdi-money-box"></i>
                </a>
              </li>
            </ul>
          </div>
          <ContactForm v-bind:item="item" v-if="item._tab == 'Contact'"></ContactForm>
          <OrderForm v-bind:item="item" v-if="item._tab == 'Order'"></OrderForm>
          <PositionsForm v-bind:item="item" v-if="item._tab == 'Positions'"></PositionsForm>
          <DeliveryForm v-bind:item="item" v-if="delivery_enabled && item._tab == 'Delivery'"></DeliveryForm>
          <PaymentsForm v-bind:item="item" v-if="item._tab == 'Payments'"></PaymentsForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '../form/contact_form'
import OrderForm from '../form/order_form'
import PositionsForm from '../form/positions_form'
import DeliveryForm from '../form/delivery_form'
import PaymentsForm from '../form/payments_form'

export default {
  components: {
    ContactForm,
    OrderForm,
    PositionsForm,
    DeliveryForm,
    PaymentsForm
  },
  methods: {
    setTab: function(item, tab){
      item._tab = tab
    }
  },
  computed: {
    delivery_enabled: function(){
      return this.$parent.delivery_enabled
    },
    items: function(){
      return this.$parent.items || []
    }
  }
}
</script>
