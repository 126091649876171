<template>
  <div id="app">
    <div v-if="!paymentRequired && signedIn">
      <NavButton></NavButton>
      <Navbar></Navbar>
      <section
        id="main"
        v-bind:class="{ 'loaded': loaded, 'justify-left': !leftMenuOpened, 'collapse-right': rightMenuOpened}"
        v-bind:style="{ 'background-image': backgroundUrl }"
      >
        <router-view :key="$route.fullPath"></router-view>
      </section>
    </div>
    <div v-else>
      <Auth action="login"></Auth>
    </div>
    <vue-progress-bar></vue-progress-bar>
    <notifications class="mt-5 pt-2" />
  </div>
</template>

<script>

import Auth from './auth/index.vue'
import Navbar from './shared/navbar.vue'
import NavButton from './shared/nav-button.vue'
import currentUser from '../services/current_user.js'
import backgrounds from '../services/backgrounds.js'

export default {
  created: function(){
    var self = this
    currentUser.setSignedIn()
    self.$event_bus.$on('notification:new', function(eventData){
      self.$store.commit('receiveNotification', eventData)

      self.$notify({
        type: eventData.variant || 'success',
        text: eventData.body,
        duration: eventData.delay || 2500,
        title: eventData.subject
      })
    })

    self.$event_bus.$on('report:created', function(eventData){
      self.$notify({
        type: 'success',
        text: 'Если скачивание не началось автоматически, нажмите на <a href="'+eventData.url+'">эту ссылку</a>.',
        duration: 30000,
        title: 'Отчет готов',
        ignoreDuplicates: false,
        closeOnClick: true
      })
      window.location = eventData.url
    })

    self.$event_bus.$on('notice', function(eventData){
      self.$notify({
        type: eventData.variant || 'success',
        text: eventData.text,
        duration: eventData.delay || 2500,
        title: eventData.title,
        ignoreDuplicates: true,
        closeOnClick: true
      })
    })
  },
  components: {
    Auth,
    Navbar,
    NavButton
  },
  computed: {
    backgroundUrl: function(){
      return 'url('+backgrounds[(this.user.bg || 1)-1]+')';
    },
    loaded: function(){
      return this.$store.getters.loaded
    },
    signedIn: function(){
      return this.$store.getters.user.id
    },
    paymentRequired: function(){
      return this.$store.getters.payment_required
    },
    user: function(){
      return this.$store.getters.user
    },
    leftMenuOpened: function(){
      return this.$store.getters.leftMenuOpened
    },
    rightMenuOpened: function(){
      return this.$store.getters.rightMenuOpened
    }
  }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../styles/application.scss";
</style>
