import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import components from '../components/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/dashboard',
      component: components.Dashboard,
      name: 'dashboard'
    },
    {
      path: '/notifications',
      component: components.Notifications,
      meta: {
        breadcrumb: [
          { name: 'уведомления' }
        ]
      }
    },
    {
      path: '/conversations',
      component: components.Conversations,
      meta: {
        breadcrumb: [
          { name: 'Диалоги' }
        ]
      }
    },
    {
      path: '/conversations/:id',
      component: components.Conversation,
      meta: {
        breadcrumb: [
          { name: 'Диалог' }
        ]
      }
    },
    {
      path: '/contacts',
      component: components.Contacts,
      meta: {
        breadcrumb: [
          { name: 'Контакты' }
        ]
      }
    },
    {
      path: '/contacts/:id',
      name: 'contact',
      component: components.Contact,
      meta: {
        breadcrumb: [
          { name: 'Контакт' }
        ]
      }
    },
    {
      path: '/orders',
      component: components.Orders,
      meta: {
        breadcrumb: [
          { name: 'Заказы' }
        ]
      }
    },
    {
      path: '/orders/:id',
      component: components.Order,
      meta: {
        breadcrumb: [
          { name: 'Заказ' }
        ]
      }
    },
    {
      path: '/tasks',
      component: components.Tasks,
      meta: {
        breadcrumb: [
          { name: 'Задачи' }
        ]
      }
    },
    {
      path: '/tasks/:id',
      component: components.Task,
      meta: {
        breadcrumb: [
          { name: 'Задачи' }
        ]
      }
    },
    {
      path: '/jobs',
      component: components.Jobs,
      meta: {
        breadcrumb: [
          { name: 'Рассылки' }
        ]
      }
    },
    {
      path: '/entities',
      component: components.Entities,
      meta: {
        breadcrumb: [
          { name: 'Справочники' }
        ]
      }
    },
    {
      path: '/entities/:id',
      component: components.Entity,
      meta: {
        breadcrumb: [
          { name: 'Справочник' }
        ]
      }
    },
    {
      name: 'entity_items',
      path: '/entities/:entity_id/items',
      component: components.Items,
      meta: {
        breadcrumb: [
          { name: 'Справочник' }
        ]
      }
    },
    {
      path: '/settings',
      component: components.Settings,
      meta: {
        breadcrumb: [
          { name: 'Настройки' }
        ]
      }
    },
    {
      path: '/settings/contacts',
      component: components.SettingsContacts
    },
    {
      name: 'groups',
      path: '/settings/groups/:scope/:id?',
      component: components.SettingsGroups,
      meta: {
        breadcrumb: [
          { name: 'Группы' }
        ]
      }
    },
    {
      name: 'roles',
      path: '/settings/roles/:scope/:id?',
      component: components.SettingsRoles,
      meta: {
        breadcrumb: [
          { name: 'Роли' }
        ]
      }
    },
    {
      name: 'contact_roles',
      path: '/settings/contact_roles/:scope/:id?',
      component: components.SettingsContactRoles,
      meta: {
        breadcrumb: [
          { name: 'РолиКлиентов' }
        ]
      }
    },
    {
      path: '/settings/integrations',
      component: components.SettingsIntegrations
    },
    {
      path: '/settings/orders',
      component: components.SettingsOrders
    },
    {
      path: '/settings/tasks',
      component: components.SettingsTasks
    },
    {
      name: 'positions',
      path: '/positions',
      component: components.Positions,
      meta: {
        breadcrumb: [
          { name: 'Каталог' }
        ]
      },
      props: { scoped: { roots: true }, show_header: true }
    },
    {
      name: 'position',
      path: '/positions/:id',
      component: components.Position,
      meta: {
        breadcrumb: [
          { name: 'Каталог' }
        ]
      }
    },
    {
      name: 'positions_old',
      path: '/settings/positions/:scope/:id?',
      component: components.SettingsPositions,
      meta: {
        breadcrumb: [
          { name: 'Каталог' }
        ]
      }
    },{
      path: '/settings/profile',
      component: components.SettingsProfile
    },{
      path: '/settings/appearance',
      component: components.SettingsAppearance
    },
    {
      path: '/settings/sites',
      component: components.SettingsSites
    },
    {
      path: '/settings/procedures',
      component: components.SettingsProcedures
    },
    {
      path: '/templates',
      component: components.Templates
    },
    {
      name: 'template',
      path: '/templates/:id',
      component: components.Template
    },
    {
      path: '/settings/triggers',
      component: components.SettingsTriggers
    },
    {
      path: '/settings/become',
      component: components.SettingsBecome
    },
    {
      path: '/webhooks',
      component: components.Webhooks,
      name: 'webhooks',
      meta: {
        breadcrumb: [
          { name: 'Вебхуки' }
        ]
      }
    },
    {
      path: '/users',
      component: components.Users,
      name: 'users',
      meta: {
        breadcrumb: [
          { name: 'Пользователи' }
        ]
      }
    },
    {
      path: '/users/:id',
      component: components.User,
      name: 'user'
    },
    {
      path: '/settings/notifications',
      component: components.SettingsCompanyNotifications
    },
    {
      path: '/settings/user_notifications',
      component: components.SettingsUserNotifications
    },
    {
      path: '/settings/document_categories',
      component: components.SettingsDocumentCategories
    },
    {
      path: '/settings/activities',
      component: components.SettingsActivities
    },
    {
      name: 'wiki',
      path: '/wiki',
      component: components.WikiSpaces,
      meta: {
        breadcrumb: [
          { name: 'Знания' }
        ]
      }
    },
    {
      name: 'wiki_space',
      path: '/wiki/:id',
      component: components.WikiPages
    },
    {
      path: '/login',
      component: components.Login,
      name: 'login'
    }
    //  { path: '/dashboard', name: 'Dashboard', component: Home, meta: { requiresLogin: true }},
    //  { path: '/login', name: 'Login', component: Login },
  ]
})

router.beforeEach((to, from, next) => {
  store.commit('setLoaded', false)
  next()
})

router.afterEach((to, from) => {
  store.commit('setLoaded', true)
  store.dispatch('rightMenuToggle', false)
  store.dispatch('leftMenuToggle', false)

})

export default router
