<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <multiselect
      v-model="item[attribute]"
      track-by="id"
      label="name"
      :multiple="multiple"
      v-bind:placeholder="placeholder"
      :options="options"
      :searchable="true"
      :disabled="disabled"
      :allow-empty="true"
      @select="change"
    >
      <template
        slot="singleLabel"
        slot-scope="{ option }">
        {{ option.name }}
      </template>
    </multiselect>
  </component>
</template>

<script>
export default {
  name: 'EnumInput',
  props: {
    placeholder: {
      type: String,
      default: 'Выберите из списка'
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: function(){}
    },
  }
}
</script>
