<template>
  <div>
    <div class="form-group">
      <label>Название</label>
      <input type="text" class="form-control" v-model="node.name">
    </div>
    <div class="form-group">
      <label>Артикул (внешний код)</label>
        <input type="text" class="form-control" v-model="node.service_id">
    </div>
    <div class="form-group">
      <label>Наличие</label>
      <input type="text" class="form-control" v-model="node.state">
    </div>
    <div class="form-group">
      <label>Цена за кг</label>
      <input type="text" class="form-control" v-model="node.price_cents">
    </div>
    <div class="form-group">
      <label>Вес (кг)</label>
      <input type="text" class="form-control" v-model="node.weight_cents">
    </div>
    <div class="form-group">
      <label>Описание</label>
      <textarea rows="3" class="form-control" v-model="node.description"></textarea>
    </div>
    <div v-if="node.fields_config && node.fields">
      <hr>
      <div  v-for="field_config in node.fields_config">
        <component
          :is="field_config.component"
          formGroupComponent="FormGroupRow"
          :label="field_config.name"
          :item="node.fields"
          :attribute="field_config.code"
          resource="position"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'node',
    'items'
  ],
  data: function () {
    return {}
  }
}
</script>

<style scoped>
</style>
