<template>
  <Widget :title="title" :loading="loading">
    <component
      :is="chartComponent"
      :data="data"
      :loading="loading"
      :legend="legend"
    >
    </component>
  </Widget>
</template>

<script>
import BarChartWidget from './charts/bar_chart_widget'
import FunnelChartWidget from './charts/funnel_chart_widget'
import LineChartWidget from './charts/line_chart_widget'
import PieChartWidget from './charts/pie_chart_widget'

export default {
  name: 'ChartWidget',
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    legend: {
      default: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartComponent: function(){
      switch (this.type) {
        case 'pie': return 'PieChartWidget'; break;
        case 'line': return 'LineChartWidget'; break;
        case 'bar': return 'BarChartWidget'; break;
        case 'funnel': return 'FunnelChartWidget'; break;
      }
    }
  },
  data: function(){
    return {
      loading: true
    }
  },
  components: {
    BarChartWidget,
    FunnelChartWidget,
    LineChartWidget,
    PieChartWidget
  }
}
</script>
