<template>
  <div class="container-fluid">
    <Header></Header>

    <div class="row" v-if="items">
      <div class="col-12 mt-3" v-bind:class="'col-sm-'+item.size*3" v-for="item in items">
        <ChartWidget
          :type="item.view"
          :title="item.name"
          :data="item.data"
        ></ChartWidget>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [
    CollectionMixin
  ],
  components: {
  },
  created(){
    this.appendItems();
  },
  data() {
      return {
        resourceName: 'dashboard',
        funnel: {
          labels: ['Impressions', 'Add To Cart', 'Buy'],
          subLabels: ['Direct', 'Social Media', 'Ads'],
          values: [
          // with the given Labels and SubLabels here's what the values represent:
          //
          // Direct, Social, Ads
          //    |      |     |
          //    v      v     v
              [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
              [3000, 1700, 1000], // Segments of "Add To Cart"
              [600,  200,  130]   // Segments of "Buy"
          ],
          colors: [
              ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
              ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
              ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
          ],
          direction: 'horizontal',
          gradientDirection: 'horizontal',
          height: 240,
          width: 600
      }
    };
  },
  methods: {
    formatDate (d){
      return d3.time.format('%x')(new Date(d))
    }
  }
}
</script>
