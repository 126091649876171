<template>
  <div class="card-body" v-if="item">
    <AutocompleteInput
      formGroupComponent="FormGroupRow"
      label="Реферал"
      :item="item"
      attribute="referral"
      resource="contact"
    ></AutocompleteInput>
  </div>
</template>

<script>
export default {
  name: 'OrderReferralForm',
  props: ['item']
}
</script>
