<template>
  <div class="contacts">
    <Header search="true">
      <li v-bind:class="{active: filtersEnabled}" class="float-right">
        <a v-on:click="toggleFilters">
          <i class="tm-icon zmdi zmdi-filter-list"></i>
        </a>
      </li>
      <!--
      <li>
        <a>
          <i class="tm-icon zmdi zmdi-view-list"></i>
        </a>
      </li>
      <li>
        <a>
          <i class="tm-icon zmdi zmdi-view-agenda"></i>
        </a>
      </li>
      <li>
        <a>
          <i class="tm-icon zmdi zmdi-chart"></i>
        </a>
      </li>
      -->
      <li class="float-left">
        <router-link to="/contacts/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
        </router-link>
      </li>
    </Header>
    <NavbarRight>
      <form @submit="search">
      <div class="card mb-5 pb-5">
        <div class="card-body">

            <br>
            <br>

            <StringInput
              label="Код"
              attribute="service_id_start"
              :item="f"
            ></StringInput>

            <AutocompleteInput
              :multiple="true"
              label="Сегменты"
              attribute="in_any_filters"
              :item="f"
              :resource="'filter'"
              :resource_params="{type_in: ['Filter::ContactFilter']}"
            ></AutocompleteInput>

            <EnumInput
              :multiple="true"
              label="Пол"
              attribute="sex_in"
              :item="f"
              :options="[{id: 1, name: 'мужской'}, {id: 2, name: 'женский'}, { id: 0, name: 'неизвестен'}]"
            ></EnumInput>

            <PeriodInput
              label="Дата создания"
              attribute="created_at"
              :item="f"
            ></PeriodInput>

            <AutocompleteInput
              :multiple="true"
              label="Ответственный"
              :item="f"
              attribute="user_id_in"
              :resource="'user'"
            ></AutocompleteInput>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-success btn-block btn-lg btn-fixed-bottom"
        >
          <i class="zmdi zmdi-search"></i>
          Найти
        </button>
      </form>
    </NavbarRight>

    <Counts></Counts>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="table-responsive">
              <table class="table table-sm table-hover inherit-link-styles">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ФИО</th>
                    <th scope="col">Телефон</th>
                    <th scope="col">Email</th>
                    <th scope="col">Пол</th>
                    <th scope="col">Возраст</th>
                    <th scope="col">Регион</th>
                    <th scope="col">Сегменты</th>
                    <th scope="col">Теги</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :class="{'unread': item.unread}"
                  >
                    <td class="text-nowrap">{{item.service_id}}</td>
                    <td class="text-nowrap"><router-link :to="{ name: 'contact', params: {id: item.id} }">{{item.name}}</router-link></td>
                    <td>{{item.phone}}</td>
                    <td>{{item.email}}</td>
                    <td>
                      <i class="zmdi" v-bind:class="{
                        'zmdi-female': item.sex == 'female',
                        'zmdi-male-alt': item.sex == 'male',
                        'zmdi-minus': item.sex == 'unknown_sex'
                      }"></i>
                    </td>
                    <td>{{item.age}}</td>
                    
                    <td>{{item.region && item.region.name}}</td>
                    <td>
                      <div v-if="item.filters">
                        <span class="badge badge-pill badge-light mr-1" v-for="filter in item.filters">
                          {{filter.name}}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div v-if="item.tags">
                        <span class="badge badge-pill badge-light mr-1" v-for="tag in item.tags">
                          #{{tag.name}}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>

    <!--<div class="card">
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" href="#">Active</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">Disabled</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active">...</div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import Counts from '../shared/counts'
import NavbarRight from '../shared/navbar-right.vue'

import CollectionMixin from '../../services/collection_mixin'
import FiltersMixin from '../../services/filters_mixin'

export default {
  mixins: [
    CollectionMixin,
    FiltersMixin
  ],
  components: {
    Counts,
    NavbarRight
  },
  data: function(){
    return {
      resourceName: 'contact'
    }
  }
}
</script>

<style scoped>
</style>
