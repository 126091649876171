<template>
  <div class="card mb-1" v-if="filtersEnabled">
    <form class="container-fluid" v-on:submit.prevent="setFilters">
      <div class="row mt-3">
        <div class="col-md-4">
          <FiltersList></FiltersList>
        </div>

        <div class="col-md-8">
          <div class="row" v-for="group in groupings">
            <div class="col">
              <div class="card bg-light mb-2 pl">
                <div class="card-header">
                  <multiselect class="mb-2" v-model="group.m" :options="orAndOptions" label="name"></multiselect>
                </div>
                <div class="card-body">
                  <div class="card mb-2" v-for="condition in group.c">
                    <div class="card-body row">
                      <div class="col-md">
                        <multiselect class="mb-1" v-model="condition.a" :options="filtersList" label="name"></multiselect>
                      </div>
                      <div class="col-md" v-if="condition.a">
                        <multiselect class="mb-1" v-model="condition.p" :options="findPredicates(predicatesList, condition.a)" label="name"></multiselect>
                      </div>
                      <div class="col-md" v-if="condition.a && condition.p">
                        <input v-model="condition.v" type="text" class="form-control w-100 mb-1">
                      </div>
                    </div>
                  </div>
                  <a class="btn btn-block btn-light pointer" v-on:click="addCondition(group)">Добавить условие</a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a class="btn btn-block btn-light pointer" v-on:click="addGrouping">Добавить группу условий</a>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <div class="float-right mt-2">
            <SaveFilter></SaveFilter>
            <button class="btn btn-success" type="submit">
              <i class="zmdi zmdi-search"></i> найти
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SaveFilter from '../filters/save_filter.vue'
import FiltersList from '../filters/filters_list.vue'

const buildFiltersList = function(config, association){
  var associationsLists = []
   _.map(config.associations, function(association_config, association_name){
    associationsLists = associationsLists.concat(buildFiltersList(association_config, association_name))
  });

  var attributesFiltersList = _.map(config.attributes, function(attribute_config, attribute_name){
    if(association){
      return {
        name: config.name + ' / ' + attribute_config.name,
        value: association + "_" + attribute_name
      }
    } else {
      return {
        name: config.name + ' / ' + attribute_config.name,
        value: attribute_name
      }
    }
  });

  return attributesFiltersList.concat(associationsLists)
}


const buildPredicatesList = function(config, association, data = {}){

  var associationsLists = []

  _.each(config.associations, function(association_config, association_name){
    buildPredicatesList(association_config, association_name, data);
  });

  _.each(config.attributes, function(attribute_config, attribute_name){
    if(association){
      data[association + "_" + attribute_name] = attribute_config
    } else {
      data[attribute_name] = attribute_config
    }
  });

  return data
}

const getValue = function(item){
  if (item){
    if (item.value){
      return item.value;
    } else {
      return item;
    }
  } else {
    return null;
  }
}

const deserializeFilters = function(data){
  return JSON.parse(atob(data).replace(/\//g, '_').replace(/\+/g, '-'))
}

const serializeFilters = function(groupings){
  var filtersObject = {
    g: groupings.map(function(grouping){
      return {
        m: getValue(grouping.m),
        c: grouping.c.map(function(condition){
          var a = getValue(condition.a)
          var p = getValue(condition.p)
          var v = getValue(condition.v)
          if (a && p && v){
            return {
              a: [a],
              p: p,
              v: [v]
            }
          } else {
            condition = {};
            return null
          }
        })
      }
    })
  }
  return btoa(unescape(encodeURIComponent(JSON.stringify(filtersObject)))).replace(/_/g, '/').replace(/-/g, '+');
}

export default {
  methods: {
    setFilterFromList: function (filterItem){
      this.currentFilter = filterItem
      this.groupings = filterItem.filters.g
      this.setFilters()
    },
    findPredicates: function(predicatesList, attribute){
      if(predicatesList[attribute.value]){
        return predicatesList[attribute.value].predicates || []
      } else {
        return []
      }
    },
    setFilters: function(){
      console.log("encode filters:")
      console.log(JSON.parse(JSON.stringify(this.groupings)))
      var serializedFilters = serializeFilters(this.groupings)
      this.$router.push({
        query: {
          q: serializedFilters
        }
      });
      this.$parent.setFilters(serializedFilters);
    },
    addCondition: function(group){
      group.c.push({});
    },
    addGrouping: function(){
      this.groupings.push({
        m: this.orAndOptions[0],
        c: [

        ]
      });
    }
  },
  components: {
    SaveFilter,
    FiltersList
  },
  computed: {
    filtersEnabled: function(){
      return this.$parent.filtersEnabled
    },
    predicatesList: function(attribute){
      return buildPredicatesList(this.$parent.filters_config);
    },
    filtersList: function(){
      return buildFiltersList(this.$parent.filters_config);
    },
    orAndOptions: function(){
      return [
        {name: 'Если все условия выполняются (И)', value: 'and'},
        {name: 'Если хотя бы одно из условий выполняется (ИЛИ)', value: 'or'}
      ]
    }
  },
  data: function(){
    return {
      "currentFilter": null,
      "groupings": []
    }
  }
}
</script>

<style scoped>
</style>
