<template>
  <div>
    <Header>
      <li class="nav-item"  >
        <a class="nav-link" v-if="node">
          {{node.name}}
        </a>
      </li>
    </Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <ul class="list-group">
              <tree-node v-for="node in flatNodes[null]" :key="random" v-bind:node="node"></tree-node>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card" v-if="node">
            <div class="card-body">
              <h4>{{node.name}}</h4>
              <component v-bind:is="componentName" v-bind:node="node"></component>

            </div>
            <div class="card-footer">
              <a v-on:click="save(node)" class="btn btn-success text-light pointer float-right">Сохранить</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMixin from '../../services/tree_mixin'

import BundlePosition from './positions/bundle'
import CategoryPosition from './positions/category'
import ServicePosition from './positions/service'
import UnitPosition from './positions/unit'
import WeightPosition from './positions/weight'


export default {
  mixins: [TreeMixin],
  components: {
    BundlePosition,
    CategoryPosition,
    ServicePosition,
    UnitPosition,
    WeightPosition
  },
  data: function(){
    return {
      resourceName: 'position'
    }
  },
  computed: {
    componentName: function(){
      if(this.node && this.node.type){
        return this.node.type.split('::')[1]
      } else {
        return 'CategoryPosition'
      }
    }
  }
}
</script>

<style scoped>
</style>
