<template>
  <div class="selectPositions">
    <div
      class="position"
      v-for="position in currentPositions"
      v-on:click="selectPosition(position)"
    >
      <div class="dummy"></div>
      <div class="in bgm-amber">
        <img
          class="bg"
          size="thumb"
          v-bind:src="position.avatar_url || placeholderImage"
          :onerror="setPlaceholderImage"
        >
        <p class="title">{{position.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Resources from '../../services/resources'
import PlaceholderImage from '../../images/placeholders/position.png'

export default {
  created: function(){
    this.loadPositions({roots: true})
  },
  data: function () {
    return {
      placeholderImage: PlaceholderImage,
      currentPositions: [],
      parentId: null
    }
  },
  methods: {
    selectPosition: function(item){
      if (item && !item.id){
        this.parentId = null
        var queryParams = {
          roots: true
        }
        this.loadPositions(queryParams)
      } else if(item.type == 'Position::CategoryPosition'){
        this.parentId = item.id
        var queryParams = {
          children_of: item.id
        }
        this.$emit('category-selected', item)
        this.loadPositions(queryParams)
      } else {
        this.$emit('position-selected', item)
      }
    },
    loadPositions: function(queryParams){
      var self = this
      Resources.position.index({q: queryParams}).then(function(response){
        if(response.data && response.data.data){
          self.currentPositions = response.data.data
        }
      })
    },
    setPlaceholderImage: function(){
      return function(source){
        source.src = PlaceholderImage;
        source.onerror = "";
        return true;
      }
    }
  }
}
</script>

<style scoped>
</style>
