<template>
  <div>
    <Header></Header>
    <table class="table">
      <tbody>
        <tr v-for="item in items">
          <td>{{item.service_id}}</td>
          <td>{{item.name}}</td>
          <td>{{item.type}}</td>
        </tr>
      </tbody>
    </table>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
    
  },
  data: function(){
    return {
      resourceName: 'integration'
    }
  }
}
</script>

<style scoped>
</style>
