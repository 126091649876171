<template>
  <div class="tasks">
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="listview lv-bordered">
              <div class="lv-header-alt clearfix">
                <h2 class="lvh-label hidden-xs">Some text here</h2>
                <div class="lvh-search">
                  <input
                    type="text"
                    placeholder="Начните печатать для поиска..."
                    class="lvhs-input"
                    v-model="searchString"
                    @keyup="syncSearchString"
                  />
                  <i class="lvh-search-close">&times;</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import FiltersMixin from '../../services/filters_mixin'
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin, FiltersMixin],
  components: {},
  data: function(){
    return {
      resourceName: 'username',
      searchString: ''
    }
  }, created: function(){
    var self = this
    self.syncSearchString = self._.debounce(function(e){
      self.setSearchString(self.searchString)
    },800)
  }
}
</script>
