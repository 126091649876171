<template>
  <div class="jobs">
    <Header search="true"></Header>
    <Cards></Cards>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import Cards from './views/cards'

import FiltersMixin from '../../services/filters_mixin'
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [
    CollectionMixin,
    FiltersMixin
  ],
  components: {
    Cards
  },
  data: function(){
    return {
      currentView: 'Cards',
      resourceName: 'job'
    }
  }
}
</script>
