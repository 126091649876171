<template>
  <div class="container">
    <Header>Мои уведомления</Header>

    <div class="row mb-2">
      <div class="col-12">
        <div class="card" v-if="bot && bot.telegram">
          <div class="card-body" v-if="!bot.telegram.connected">
            <a target="_blank" class="btn btn-success text-light pointer float-right" v-bind:href="bot.telegram.connect_url">Подключить</a>
            <h6>Подключите бота в телеграм, чтобы быть в курсе событий!<br><small>даже когда вы не в системе!</small></h6>
          </div>
          <div class="card-body" v-else>
            <a class="btn btn-warning text-light pointer float-right" v-on:click="destroyBot">Отключить</a>
            <h6>
              Поздравляем, бот успешно подключен!
              <br>
              <small>
                Чтобы посмотреть уведомления, полученные через бота, перейдите <a target="_blank" v-bind:href="bot.telegram.url">по этой ссылке</a>
              </small>
            </h6>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6>Настройте уведомления о только о тех событиях, которые вам важны</h6>
          </div>
          <div class="table-responsive">
            <table class="table table-sm table-hover text-center">
              <thead>
                <tr>
                  <th class="text-left">Событие</th>
                  <th>Email</th>
                  <th>Web</th>
                  <th>Bot</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items">
                  <td class="text-left" v-bind:title="item.description">
                    {{item.name}}
                  </td>
                  <td>
                    <span v-if="itemDisabled(item, 'by_email')">
                      <span v-if="item.by_email_company_state == 'disabled'">-</span>
                      <span v-else>+</span>
                    </span>
                    <span v-else>
                      <input
                        type="checkbox"
                        v-on:click="updateItem(item, 'by_email')"
                        v-bind:checked="itemChecked(item, 'by_email')"
                      >
                    </span>
                  </td>
                  <td>
                    <span v-if="itemDisabled(item, 'by_web')">
                      <span v-if="item.by_web_company_state == 'disabled'">-</span>
                      <span v-else>+</span>
                    </span>
                    <span v-else>
                      <input
                        type="checkbox"
                        v-on:click="updateItem(item, 'by_web')"
                        v-bind:checked="itemChecked(item, 'by_web')"
                      >
                    </span>
                  </td>
                  <td>
                    <span v-if="itemDisabled(item, 'by_chatbot')">
                      <span v-if="item.by_chatbot_company_state == 'disabled'">-</span>
                      <span v-else>+</span>
                    </span>
                    <span v-else>
                      <input
                        type="checkbox"
                        v-on:click="updateItem(item, 'by_chatbot')"
                        v-bind:checked="itemChecked(item, 'by_chatbot')"
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <infinite-loading
              @infinite="infiniteItems"
              :identifier="infiniteId"
            ></infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../../services/collection_mixin'
import Resources from '../../../services/resources'

export default {
  mixins: [CollectionMixin],
  created: function(){
    this.fetchBotConfig()
  },
  methods: {
    destroyBot: function(){
      var self = this
      Resources.bot.destroy('new').then(function(response){
        self.bot = response.data.data
      })
    },
    fetchBotConfig: function(){
      var self = this
      Resources.bot.show('new').then(function(response){
        self.bot = response.data
      })
    },
    itemChecked: function(item, channel){
      if(item[channel] != 'disabled'){
        return true
      }
    },
    itemDisabled: function(item, channel){
      if(item[channel + '_company_state'] != 'default'){
        return true
      }
    },
    updateItem: function(item, channel){
      if(item[channel] == 'disabled'){
        item[channel] = 'immediatly'
      } else {
        item[channel] = 'disabled'
      }
      this.save(item)
    }
  },
  data: function(){
    return {
      bot: null,
      resourceName: 'notification_user_configuration',
      notificationChannelStates: {
        default: 'По умолчанию (Отправлять)',
        disabled: 'Выключено (У всех пользователей, независимо от настроек)',
        immediatly: 'Включено (У всех пользователей, независимо от настроек)'
      }
    }
  }
}
</script>
