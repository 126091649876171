import Resources from './resources.js'
import TreeNode from 'components/shared/tree_node.vue'

const mixin = {
  props: [],
  data: function(){
    return {
      q: null,
      limit: 50,
      offset: 0,
      items: [],
      node: null,
      loading: false,
      flatNodes: {},
      random: null
    }
  },
  created: function () {
    this.fetchChildrenOrRoots(this.$route.params.scope, this.$route.params.id)
  },
  beforeRouteUpdate: function(to, from, next){
    this.fetchNode(to.params.id)
    this.fetchChildrenOrRoots(to.params.scope, to.params.id)
    next()
  },
  components: {
    TreeNode
  },
  computed: {
  },
  methods: {
    save: function(item){
      var self = this
      Resources[this.resourceName].save(item).then(function(response){
        self._.each(response.data.data, function(v,k){
          item[k] = v
        })
      }).catch(function(error){
        alert('произошла ошибка')
      })
    },
    selectNode: function(nodeItem){
      this.node = nodeItem;
    },
    selectTreeNode: function(nodes){
      var node = nodes[0]
      var flatNode = this.flatNodes[node.data.id]
      var self = this

      flatNode.isExpanded = node.isExpanded

      if (!node.isLeaf && !flatNode.childrenLoaded){
        flatNode.childrenLoaded = true
        this.fetchTree(nodes[0].data.id, 'children_of')
      }

    },
    fetchNode: function(id, callback){
      var self = this
      if(id){
        Resources[this.resourceName].show(id).then(function(response){
          self.node = response.data
          if(callback){ callback(self.node) }
        })
      } else {
        self.node = null;
        if(callback){ callback(self.node) }
      }
    },
    fetchTree: function(id, scope, callback){
      var self = this
      var params = {}

      if (scope){
        params[scope] = id
      } else if (id){
        params.self_and_ancestors_and_siblings_of = id
      } else {
        params.roots = true
      }

      Resources[this.resourceName].index({q: params}).then(function(response){
        var nodesSubTree = {}

        self._.each(response.data.data, function(item){
          if(!nodesSubTree[item.parent_id]){
            nodesSubTree[item.parent_id] = []
          }
          nodesSubTree[item.parent_id].push(item)
        })

        self.flatNodes = Object.assign(self.flatNodes, nodesSubTree)
        if(callback){ callback(response.data.data) }
      })
    },

    fetchChildrenOrRoots: function(scope, id, callback){
      var self = this
      var params = {}

      if (id){
        params[scope] = id
      } else {
        params[scope] = true
      }

      Resources[this.resourceName].index({q: params}).then(function(response){
        var nodesSubTree = {}

        self._.each(response.data.data, function(item){
          if(!nodesSubTree[item.parent_id]){
            nodesSubTree[item.parent_id] = []
          }
          nodesSubTree[item.parent_id].push(item)
        })

        self.flatNodes = Object.assign(self.flatNodes, nodesSubTree)
        if(callback){ callback(response.data.data) }
        self.$forceUpdate()
      })
    }
  }
}

export default mixin
