<template>
  <bar-chart :data="data" :dataset="{borderWidth: 0}" :legend="legend"></bar-chart>
</template>

<script>
export default {
  name: 'BarChartWidget',
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    legend: {
      type: Boolean
    }
  }
}
</script>
