<template>
  <div>
    <Header search="true">
      <li class="float-right">
        <a v-on:click="readAll" class="pointer">
          <i class="tm-icon zmdi zmdi-check-all"></i>
          <span class="nav-title">Прочитать все</span>
        </a>
      </li>
    </Header>
    <div class="container">
      <div class="row">
        <div class="col-md-12 pt-3">
          <div class="card mt-2" v-for="item in items" v-on:mouseleave="readOne(item)">
            <div class="card-body">
              <i class="zmdi zmdi-check float-right text-success zmdi-hc-2x" v-if="item.readed"></i>
              <b>{{item.subject}}</b>
              <div v-html="item.body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [
    CollectionMixin
  ],
  components: {
  },
  data: function(){
    return {
      resourceName: 'notification'
    }
  },
  methods: {
    readAll: function(){
      var self = this
      this.doAction('read', {all: true}).then(function(){
        self._.each(self.items, function(item){
          item.readed = true
        })
      })
    },
    readOne: function(item){
      this.doAction('read', {notification_ids: [item.id]}).then(function(){
        item.readed = true
      })
    }
  }
}
</script>
