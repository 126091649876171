<template>
  <div class="job">
    orders
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  }
}
</script>

<style scoped>
</style>
