<template>
  <div>
    <Header></Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" v-for="item in items" :key="item.id">
          <div class="card mb-1 mt-1">
            <div class="card-body">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
  },
  data: function () {
    return {
      resourceName: 'task_kind'
    }
  }
}
</script>

<style scoped>
</style>
