<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <multiselect
      :multiple="multiple"
      v-model="item[attribute]"
      track-by="id"
      label="name"
      :placeholder="placeholder"
      :searchable="true"
      :internal-search="false"
      :allow-empty="true"
      :options="findedItems"
      :loading="loading"
      :disabled="disabled"
      @open="findItems"
      @select="change"
      @remove="nullifyOrChange"
      @search-change="findItems"
    >
      <template
        slot="singleLabel"
        slot-scope="{ findedItem }">
        <strong v-if="findedItem">{{ findedItem.name }}</strong>
      </template>
    </multiselect>
  </component>
</template>

<script>
import Resources from '../../services/resources'

export default {
  name: 'AutocompleteInput',
  props: {
    resource: {
      type: String,
      required: true
    },
    resource_params: {
      type: Object,
      default: function(){ return {} }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Начните печатать'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    },
    change: {
      type: Function,
      default: function(){}
    },
    nullify: {
      type: Function
    }
  },
  methods: {
    findItems: function(searchString){
      var self = this
      self.loading = true

      Resources[self.resource].index({
        s: searchString,
        q: self.resource_params
      }).then(function(response){
        self.findedItems = response.data.data
        self.loading = false
      }).catch(function(response){
        self.loading = false
      })
    },
    nullifyOrChange: function(){
      this.nullify || this.change
    }
  },
  data: function(){
    return {
      loading: false,
      findedItems: []
    }
  }
}
</script>
