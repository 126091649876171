<template>
  <div>
    <div class="text-left" v-for="i in data">
      <b class="ml-5">{{i.name}}</b>
      <line-chart
        :data="[i]"
        :legend="false"
        :height="100"
        >
      </line-chart>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineChartWidget',
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    legend: {
      type: Boolean
    }
  }
}
</script>
