<template>
  <div>
    <div class="form-group">
      <label>Название</label>
      <input type="text" class="form-control" v-model="node.name">
    </div>
    <div class="form-group">
      <label>Артикул (внешний код)</label>
        <input type="text" class="form-control" v-model="node.service_id">
    </div>
    <div class="form-group">
      <label>Наличие</label>
      <input type="text" class="form-control" v-model="node.state">
    </div>
    <div class="form-group">
      <label>Цена</label>
      <input type="text" class="form-control" v-model="node.price_cents">
    </div>
    <div class="form-group">
      <label>Описание</label>
      <textarea rows="3" class="form-control" v-model="node.description"></textarea>
    </div>
    <div class="form-group">
      <label>Позиции в наборе</label>
      <div v-for="(bundled_item, index) in node.bundled_items" class="row">
        <div class="col"><input class="form-control form-control-sm" type="text" v-model="bundled_item.name"></div>
        <div class="col"><input class="form-control form-control-sm" type="text" v-model="bundled_item.position_id"></div>
        <div class="col"><input class="form-control form-control-sm" type="text" v-model="bundled_item.quantity_cents"></div>
        <div class="col"><input class="form-control form-control-sm" type="text" v-model="bundled_item.price_cents"></div>
        <div class="col"><i class="fa fa-times" @click="destroyItem(bundled_item, index)">x</i></div>
      </div>
      <div><span @click="addItem()">+</span></div>
    </div>
    <div v-if="node.fields_config && node.fields">
      <hr>
      <div  v-for="field_config in node.fields_config">
        <component
          :is="field_config.component"
          formGroupComponent="FormGroupRow"
          :label="field_config.name"
          :item="node.fields"
          :attribute="field_config.code"
          resource="position"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'node'
  ],
  data: function () {
    return {}
  },
  methods: {
    destroyItem(bundled_item, index){
      this.node.bundled_items.splice(index, 1)
    },
    addItem(){
      this.node.bundled_items.push({})
    }
  }
}
</script>
