<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-sm table-hover inherit-link-styles">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">№&nbsp;Заказа</th>
                  <th scope="col">Дата</th>
                  <th scope="col">Тип</th>
                  <th scope="col">Клиент</th>
                  <th scope="col">Статус</th>
                  <th scope="col" v-if="delivery_enabled">Доставка</th>
                  <th scope="col">Сумма</th>
                  <th scope="col">Оплата</th>
                  <th scope="col">Группа</th>
                  <th scope="col">Ответственный</th>
                  <th scope="col">Фильтры</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="nowrap"
                  v-for="item in items"
                  :class="{
                    'unread': item.unread,
                    'unread-row': item.unread,
                    'read-row': !item.unread
                  }"
                  :style="{
                    'background-color': item.state && item.state.color
                  }"
                >
                  <td>
                    <!--
                    <b-form-checkbox
                      v-model="item._selected"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                    </b-form-checkbox>
                    -->
                    <FunctionsBadge
                      :item="item"
                      :functions="item.functions"
                    ></FunctionsBadge>
                  </td>
                  <td>
                    <router-link :to="'/orders/'+item.id">
                      <span>
                        {{item.service_id}}
                      </span>
                    </router-link>
                  </td>
                  <td>
                    <span v-bind:title="item.service_created_at | moment('DD MMM YYYY, hh:mm')">
                      {{item.service_created_at | moment("DD MMM")}}&nbsp;
                    </span>
                  </td>
                  <td>{{item.kind && item.kind.name}}</td>
                  <td>
                    <router-link v-if="item.contact" :to="'/contacts/'+item.contact.id">
                      {{item.contact.name}}
                    </router-link>
                  </td>
                  <td>
                    <StatesBadge
                      :item="item"
                      :state="item.state"
                      :transitions="item.transitions"
                    >
                    </StatesBadge>
                  </td>
                  <td v-if="delivery_enabled">
                    <span v-if="item.delivery && item.delivery.date" v-b-tooltip.hover.right v-bind:title="item.delivery.date | moment('DD MMM YYYY, hh:mm')">
                      {{item.delivery.date | moment("DD MMM")}}&nbsp;
                    </span>
                  </td>
                  <td>{{item.amount_cents | money}}</td>
                  <td>{{item.paid_state && item.paid_state.name}}</td>
                  <td>{{item.group && item.group.name}}</td>
                  <td>{{item.user && item.user.name}}</td>
                  <td>
                    <span v-if="item.filters">
                      <span class="badge badge-pill badge-light mr-1 text-uppercase" v-for="filters in item.filters">
                        {{filters.name}}
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    delivery_enabled: function(){
      return this.$parent.delivery_enabled
    },
    items: function(){
      return this.$parent.items || []
    }
  },
  methods: {
    save(item){
      this.$parent.save(item)
    }
  }
}
</script>
