<template>
  <div class="container-fluid">
    <Header>Настройки уведомлений</Header>
    <div class="row">
      <div class="col">
        <div class="card mt-2" v-for="item in items">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="">Название</label>
                <input type="text" class="form-control" v-model="item.name">
              </div>
              <div class="form-group">
                <label for="">Описание</label>
                <textarea rows="2" class="form-control" v-model="item.description"></textarea>
              </div>

              <div class="form-group">
                <label for="">Тема уведомления</label>
                <input type="text" class="form-control" v-model="item.subject">
              </div>
              <div class="form-group">
                <label for="">Текст уведомления</label>
                <textarea rows="4" class="form-control" v-model="item.body"></textarea>
              </div>


              <div class="form-group">
                <label for="">Количество минут до повтора</label>
                <input type="text" class="form-control" v-model="item.guard_minutes">
              </div>


              <div class="form-group">
                <label for="">Email</label>
                <select class="form-control" v-model="item.by_email">
                  <option v-for="state in notificationChannelStates" v-bind:value="state.id">{{state.name}}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="">Web</label>
                <select class="form-control" v-model="item.by_web">
                  <option v-for="state in notificationChannelStates" v-bind:value="state.id">{{state.name}}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="">ЧатБот</label>
                <select class="form-control" v-model="item.by_chatbot">
                  <option v-for="state in notificationChannelStates" v-bind:value="state.id">{{state.name}}</option>
                </select>
              </div>
            </form>

          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary float-right" v-on:click="save(item)">Сохранить</button>
          </div>
        </div>

        <infinite-loading
          @infinite="infiniteItems"
          :identifier="infiniteId"
        ></infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
  },
  data: function(){
    return {
      resourceName: 'notification_configuration',
      notificationChannelStates: [
        { id: 'default', name: 'Включить или выключить - на выбор пользователя'},
        { id: 'disabled', name: 'Выключить у всех пользователей'},
        { id: 'immediatly', name: 'Включить у всех пользователей'}
      ]
    }
  }
}
</script>
