<template>
  <div class="d-flex justify-content-center">
    <div class="evt text-muted">
      <div class="body text-center">
        <i class="zmdi" :class="payload.icon"></i>
        <span v-html="payload.body"></span>
      </div>
      <br>
      <div class="text-center">
        <small :title="payload.created_at">
          {{payload.created_at | moment("from", "now", true) }} назад
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object }
  },
  computed: {
    payload: function(){
      if (this.item && this.item.payload){
        return this.item.payload
      } else {
        return {}
      }
    }
  }
}
</script>
