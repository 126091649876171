<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div v-for="item in items" class="card text-center mb-1">
          <div class="card-header">
            <div>
              <h5 class="text-left">{{item.name}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    items: function(){
      return this.$parent.items || []
    }
  }
}
</script>
