<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <input type="text" v-model="item[attribute]" class="form-control"></input>
  </component>
</template>

<script>
import FormGroup from '../form/form_group'
import FormGroupRow from '../form/form_group_row'

export default {
  name: 'NumericInput',
  props: {
    placeholder: {
      type: String,
      default: 'Начните печатать'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    }
  },
  components: {
    FormGroup
  },
  data: function () {
    return {}
  }
}
</script>
