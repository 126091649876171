const mixin = {
  methods: {
    resubscribe: function(){
      var channelName = this.resourceName
      this.$store.commit('additionalResubscribe', this.channelName)
    },
    stopSubscribe: function(){
      // TODO
    }
  },
  created: function(){
    this.resubscribe()
  },
  beforeDestroy: function(){
    this.stopSubscribe()
  }
}

export default mixin
