<template>
  <div class="contact-show" v-if="item">
    <Header></Header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <form class="card" v-on:submit="save(item)">
            <div class="card-body">
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control" placeholder="ivan@example.com" v-model="item.email">
                  <small class="form-text text-muted"></small>
                </div>
                <div class="form-group">
                  <label>Телефон</label>
                  <input type="text" class="form-control" placeholder="+7(999)999-99-99" v-model="item.phone">
                  <small class="form-text text-muted"></small>
                </div>
                <div class="form-group">
                  <label>Имя</label>
                  <input type="text" class="form-control" placeholder="Иван" v-model="item.first_name">
                </div>
                <div class="form-group">
                  <label>Фамилия</label>
                  <input type="text" class="form-control" placeholder="Иванов" v-model="item.last_name">
                </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-success text-light pointer float-right" type="submit">Сохранить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemMixin from '../../services/item_mixin'

export default {
  data: function() {
    return {
      resourceName: 'user'
    }
  },
  mixins: [
    ItemMixin
  ],
  components: {
    
  }
}
</script>

<style scoped>
</style>
