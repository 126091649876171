<template>
  <div>
    <Header></Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div v-for="item in items" :key="item.id" class="card mb-1 mt-1">
            <h4 class="card-header">{{item.name}}</h4>

            <div class="card-footer">
              <a class="btn btn-success text-light float-right pointer" v-on:click="save(item)">сохранить</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  components: {
    
  },
  data: function(){
    return {
      resourceName: 'document_category'
    }
  }
}
</script>

<style scoped>
</style>
