<template>
  <div>
    <Header></Header>
    <div class="container">
      <div class="row">

        <div class="col-md-12">
          <div class="card">
            <div class="listview lv-bordered">
              <div class="lv-header-alt clearfix">
                <h2 class="lvh-label hidden-xs">Some text here</h2>
                <div class="lvh-search">
                  <input type="text" placeholder="Начните печатать для поиска..." class="lvhs-input">
                  <i class="lvh-search-close">&times;</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card chat">
            <div class="card-body">

              <div class="listview lv-message">
                <div class="lv-body">
                  <div class="lv-item media" v-bind:class="{'right': message.direction == 'incoming'}" v-for="message in messages">
                    <div class="lv-avatar bg-success " v-bind:class="{'float-right': message.direction == 'incoming', 'float-left':  message.direction == 'outgoing'}">
                      <img src="img/profile-pics/1.jpg" alt="">
                    </div>
                    <div class="media-body">
                      <b v-if="message.subject">{{message.subject}}<br></b>
                      <div class="ms-item" v-html="message.body"></div>
                      <small class="ms-date" v-bind:title="message.sent_at">{{message.type_name}} <i class="zmdi zmdi-time"></i> {{message.sent_at | moment("from", "now") }} </small>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>

                <div class="lv-footer ms-reply">
                    <hr></hr>
                    <textarea v-model="newMessageText" placeholder="Напечатайте тут текст сообщения для отправки клиенту..."></textarea>
                    <button class="btn btn-primary" v-on:click="sendMessage"><i class="zmdi zmdi-mail-send"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resources from '../../services/resources.js'
import ItemMixin from '../../services/item_mixin'
import SubscribeMixin from '../../services/subscribe_mixin'


export default {
  created: function(){
    this.reloadMessages()
  },
  data: function() {
    return {
      resourceName: 'conversation',
      channel: null,
      messages: [],
      newMessageText: ""
    }
  },
  methods: {
    reloadMessages: function(){
      var self = this
      Resources.message.index({
        q: {
          conversation_id_in: this.$route.params.id
        }
      }).then(function(response){
        self.messages = response.data.data
      })
    },
    sendMessage: function(){
      var self = this

      self.messages.push({
        description: self.newMessageText
      })
      self.newMessageText = ''
    }
  },
  computed: {
    channelName: function(){
      return this.$route.params.id;
    }
  },
  mixins: [
    ItemMixin,
    SubscribeMixin
  ],
  components: {
  }
}
</script>
