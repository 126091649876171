<template>
  <aside id="sidebar-right" v-bind:class="{'toggled': toggled}">
    <div class="sidebar-inner c-overflow">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
export default {
  computed: {
    toggled: function() {
      return this.$store.getters.rightMenuOpened;
    }
  }
}
</script>
