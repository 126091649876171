<template>
  <div
    class="card d-flex flex-column task-row"
    :class="{'collapsed': !opened}"
  >
    <div class="p-1 pl-3 d-flex">
      <div style="min-width:250px;">
        <PriorityBadge
          v-bind:item="item"
        ></PriorityBadge>

        <DatepickerBadge
          class="text-muted"
          :item="item"
          :attribute="'deadline_at'"
        ></DatepickerBadge>

        <StatesBadge
          class="text-muted"
          :item="item"
          :state="item.state"
          :transitions="item.transitions"
        >
        </StatesBadge>
      </div>

      <div class="mr-auto pointer" @click="toggle">
        <a class="text-break">
          <span class="font-weight-bold" v-if="item.unread">{{item.service_id}} {{item.name}}</span>
          <span v-else>{{item.service_id}} {{item.name}}</span>
        </a>
      </div>

      <div>
        <span class="badge badge-pill badge-light text-uppercase text-muted">
          {{item.user && item.user.name}}
        </span>
      </div>

    </div>


    <div class="p-1 pl-3 d-flex justify-content-between" v-if="opened">
      <div>
        <span :title="item.order_position.name"
          class="badge badge-pill badge-light text-muted"
          style="max-width:150px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor:help;"
          v-if="item.order_position"
        >
          {{item.order_position.name}}
        </span>

        <span class="badge badge-pill badge-light text-muted" v-if="item.order">
          <i class="zmdi zmdi-shopping-basket"></i>
          <router-link :to="'/orders/'+item.order.id">
            {{item.order && item.order.service_id}}
          </router-link>
        </span>

        <span class="badge badge-pill badge-light text-muted" v-if="item.contact">
          <i class="zmdi zmdi-account"></i>
          <router-link :to="'/contacts/'+item.contact.id">
            {{item.contact && item.contact.name}}
          </router-link>
        </span>

        <span class="badge badge-pill badge-light text-muted" v-for="tag in item.tags">
          #{{tag.name}}
        </span>
      </div>
    </div>

    <modal
      :name="'task_'+item.id"
      :adaptive="true"
      :width="'85%'"
      :height="'auto'"
      :scrollable="true"
      :focusTrap="true"
    >

      <div class="card-body">
        <form v-on:submit="save(item)">
          <div class="row">
            <div class="col-sm-8">
              <TextareaInput
                :label="'Название'"
                :attribute="'name'"
                :item="item"
                :rows="1"
              ></TextareaInput>

              <TextareaInput
                :rows="4"
                label="Описание"
                :item="item"
                attribute="description"
              ></TextareaInput>

              <TagsInput
                label="Теги"
                :item="item"
                attribute="tags"
                resource="task"
              >
              </TagsInput>

              <div v-if="item.fields_config && item.fields">
                <hr>
                <div  v-for="field_config in item.fields_config">
                  <component
                    :is="field_config.component"
                    formGroupComponent="FormGroupRow"
                    :label="field_config.name"
                    :item="item.fields"
                    :attribute="field_config.code"
                    resource="task"
                  ></component>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <EnumInput
                label="Вид"
                :options="kinds"
                attribute="kind"
                :item="item"
              ></EnumInput>

              <AutocompleteInput
                label="<i class='zmdi zmdi-account'></i> Клиент"
                resource="contact"
                :attribute="'contact'"
                :item="item"
                :change="clearContactRelatedFields"
                :nullify="clearContactRelatedFields"
              ></AutocompleteInput>

              <AutocompleteInput
                label="<i class='zmdi zmdi-shopping-basket'></i> Заказ"
                resource="order"
                :resource_params="orderParams"
                :attribute="'order'"
                :item="item"
                :change="clearOrderRelatedFields"
                :nullify="clearOrderRelatedFields"
              ></AutocompleteInput>

              <AutocompleteInput
                label="Позиция"
                resource="order_position"
                :resource_params="orderPositionParams"
                :attribute="'order_position'"
                :item="item"
              ></AutocompleteInput>

              <AutocompleteInput
                label="Ответственный"
                resource="user"
                :attribute="'user'"
                :item="item"
              ></AutocompleteInput>
            </div>
          </div>
          <FormActions :item="item"></FormActions>
        </form>
      </div>


    </modal>


  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function(){
    return {
      opened: false
    }
  },
  methods: {
    toggle(item){
      this.$modal.show("task_"+this.item.id)
      this.opened = !this.opened
    },
    save(item){
      this.$parent.save(item)
    },
    clearContactRelatedFields(){
      this.deleteRelationship(this.item, 'order')
      this.clearOrderRelatedFields()
    },
    clearOrderRelatedFields(){
      this.deleteRelationship(this.item, 'order_position')
    },
    deleteRelationship(item, relationship){
      this.$delete(item, relationship)
    }
  },
  computed: {
    kinds: function(){
      return this.$store.getters['modules'].tasks.settings.kind;
    },
    orderParams: function(){
      return {
        contact_id_in: [this.item.contact.id]
      }
    },
    orderPositionParams: function(){
      return {
        order_id_in: [this.item.order.id],
        services: true
      }
    }
  }
}
</script>
