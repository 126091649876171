<template>
  <span class="badge badge-pill badge-light">
    <date-picker
      v-if="opened"
      v-model="item[attribute]"
      @input="setDate"
    >
    </date-picker>
    <span v-else @click="togglePicker">
      <b class="zmdi zmdi-time"></b>
      <span v-if="item[attribute]">
        {{item[attribute] | moment("DD.MM.YYYY") }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'DatepickerBadge',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    attribute: {
      type: String,
    },
    item: {}
  },
  data: function(){
    return {
      opened: false,
    }
  },
  methods: {
    togglePicker(){
      this.opened = !this.opened
    },
    setDate(date){
      this.item[this.attribute] = date
      this.$parent.save(this.item)
      this.togglePicker()
    }
  }
}
</script>
