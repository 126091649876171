<template>
  <FormGroup v-bind:label="label" v-bind:item="item" v-bind:attribute="attribute">
    <textarea-autosize
      :rows="rows"
      type="text"
      v-model="item[attribute]"
      class="form-control"
    ></textarea-autosize>
  </FormGroup>
</template>

<script>
import FormGroup from '../form/form_group'

export default {
  name: 'TextareaInput',
  props: {
    placeholder: {
      type: String,
      default: 'Начните печатать'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    },
    rows: {
      type: Number,
      required: true,
      default: 1
    }
  },
  components: {
    FormGroup
  },
  data: function () {
    return {}
  }
}
</script>
