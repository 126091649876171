<template>
  <div class="orders" style="margin-top: -35px;">
    <Orders v-bind:scoped="{contact_id_in: [this.$route.params.id]}"></Orders>
  </div>
</template>

<script>
import Orders from '../../orders/index'

export default {
  data: function () {
    return {}
  },
  components: {
    Orders
  }
}
</script>

<style scoped>
</style>
