<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <date-picker v-model="item[attribute]">
      <template #default="{ inputValue, togglePopover, hidePopover }">
        <div
          class="bg-white p-1 w-full border rounded"
          @click.stop="dateSelected($event, item[attribute], togglePopover)"
        >
          <div class="flex flex-wrap">
            <button
              class="btn btn-sm btn-light"
              ref="button"
            >
              <span v-if="selected">{{displayValue}}</span>
              <span v-else>{{placeholder}}</span>
              <i
                class="zmdi zmdi-close"
                @click.stop.prevent="removeDate(hidePopover)"
              ></i>
            </button>
          </div>
        </div>
      </template>
    </date-picker>
  </component>
</template>

<script>
import FormGroup from '../form/form_group'
import FormGroupRow from '../form/form_group_row'

export default {
  name: 'DateInput',
  props: {
    placeholder: {
      type: String,
      default: 'Выберите дату'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    }
  },
  components: {
    FormGroup,
    FormGroupRow
  },
  computed: {
    selected(){
      return !!this.item[this.attribute]
    },
    displayValue(){
      var value = this.item[this.attribute]

      if(typeof(value) == 'string'){
        value = new Date(value)
      }

      return value.toLocaleDateString()
    }
  },
  methods: {
    removeDate(hide) {
      this.item[this.attribute] = null
      hide();
    },
    dateSelected(e, date, toggle) {
      toggle({ ref: e.target })
      if(date){
        if(typeof(date) == 'string'){
          date = new Date(date)
        }
        this.item[this.attribute] = date.toUTCString();
      } else {
        this.item[this.attribute] = date
      }
    }
  }
}
</script>
