<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <input
      type="text"
      class="form-control"
      v-model.lazy="item[attribute + '_in_currency']"
      @change="setAttribute"
    ></input>
  </component>
</template>

<script>
import FormGroup from '../form/form_group'
import FormGroupRow from '../form/form_group_row'

export default {
  created(){
    this.item[this.attribute + '_in_currency'] = String(parseFloat(this.item[this.attribute]) / 100.0)
  },
  name: 'MoneyInput',
  props: {
    placeholder: {
      type: String,
      default: 'Начните печатать'
    },
    label: {
      type: String,
      required: true
    },
    item: {
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    }
  },
  components: {
    FormGroup,
    FormGroupRow
  },
  methods: {
    setAttribute(){
      var value = this.item[this.attribute + '_in_currency'];
      value = parseFloat(value.replace(',', '.'))

      this.item[this.attribute] = parseInt(value * 100)
    }
  }
}
</script>
