import Vue from 'vue'

import FormBlock from './form/form_block'
Vue.component('FormBlock', FormBlock)

import FormGroup from './form/form_group'
Vue.component('FormGroup', FormGroup)

import FormGroupRow from './form/form_group_row'
Vue.component('FormGroupRow', FormGroupRow)

import FormActions from './form/form_actions'
Vue.component('FormActions', FormActions)

import StringInput from './inputs/string_input'
Vue.component('StringInput', StringInput)

import BooleanInput from './inputs/boolean_input'
Vue.component('BooleanInput', BooleanInput)

import PhoneInput from './inputs/phone_input'
Vue.component('PhoneInput', PhoneInput)

import EmailInput from './inputs/email_input'
Vue.component('EmailInput', EmailInput)

import TagsInput from './inputs/tags_input'
Vue.component('TagsInput', TagsInput)

import NumericInput from './inputs/numeric_input'
Vue.component('NumericInput', NumericInput)

import MoneyInput from './inputs/money_input'
Vue.component('MoneyInput', MoneyInput)

import TextareaInput from './inputs/textarea_input'
Vue.component('TextareaInput', TextareaInput)

import EnumInput from './inputs/enum_input'
Vue.component('EnumInput', EnumInput)

import AutocompleteInput from './inputs/autocomplete_input'
Vue.component('AutocompleteInput', AutocompleteInput)

import DateInput from './inputs/date_input'
Vue.component('DateInput', DateInput)

import PeriodInput from './inputs/period_input'
Vue.component('PeriodInput', PeriodInput)

import PriorityInput from './inputs/priority_input'
Vue.component('PriorityInput', PriorityInput)

////////////////////////////////////////////////

import Widget from './widgets/widget'
Vue.component('Widget', Widget)

import ChartWidget from './widgets/chart_widget'
Vue.component('ChartWidget', ChartWidget)

////////////////////////////////////////////////

import Header from './common/header'
Vue.component('Header', Header)

import StatesBadge from './common/states_badge'
Vue.component('StatesBadge', StatesBadge)

import FunctionsBadge from './common/functions_badge'
Vue.component('FunctionsBadge', FunctionsBadge)

import DatepickerBadge from './common/datepicker_badge'
Vue.component('DatepickerBadge', DatepickerBadge)

import PriorityBadge from './common/priority_badge'
Vue.component('PriorityBadge', PriorityBadge)
