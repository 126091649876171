<template>
  <div>
    <ul class="list-group">
      <li class="list-group-item pointer" v-on:click="setFilter(item)" v-for="item in config.filters.saved">{{item.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    config: function(){
      return this.$parent.$parent.config
    }
  },
  methods: {
    setFilter: function (item) {
      this.$parent.setFilterFromList(item)
    }
  }
}
</script>
