<template>
  <div>
    <Header search="true"></Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 pt-3">
          <div class="card mb-2" v-for="item in items">
            <div class="card-body">
              <h4>
                #{{item.service_id}}
                <router-link :to="'wiki/' + item.service_id">
                  {{item.name}}
                </router-link>
              </h4>
            </div>
          </div>
          <infinite-loading
            @infinite="infiniteItems"
            :identifier="infiniteId"
          ></infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../../services/collection_mixin'

export default {
  mixins: [
    CollectionMixin
  ],
  components: {
  },
  data: function(){
    return {
      resourceName: 'wiki_space'
    }
  }
}
</script>
