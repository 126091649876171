<template>
  <div class="card">
    <div class="card-body text-center">
      <h5>{{title}}</h5>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    title: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
