<template>
  <div class="card">
    <div class="card-body card-padding">
      <form role="form" v-on:submit="resetPassword">
        <div class="form-group fg-line has-error">
          <label>Ваш email</label>
          <input class="form-control input-sm" v-model="email" required="true" placeholder="user@example.com" type="email" />
        </div>
        <div class="text-center">
          <button v-bind:disabled="loading" class="btn btn-success btn-block m-t-10 waves-effect" type="submit">
            <i class="zmdi zmdi-rotate-right zmdi-hc-spin" v-show="loading"></i>
            <span v-show="!loading">Сбросить пароль</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function(){
    return {
      email: '',
      loading: false,
      invalid: false,
      success: false
    }
  },
  methods: {
    resetInvalid: function(){
      this.invalid = false
      this.success = false
    },
    resetPassword: function(){
      this.loading = true
      this.success = false
      var self = this

      axios.post('/api/v2/users/reset_password.json', {
        username: this.email
      })
      .then(function (response) {
        self.success = true
        self.email = ''

        setTimeout(function(){
          self.loading = false
        }, 2000)
      })
      .catch(function (error) {
        self.invalid = true
        self.loading = false
      });
    }
  }
}
</script>

<style scoped>
</style>
