<template>
  <div id="auth-screen">
    <div id="auth-form">
      <img src="../../../../assets/images/logo.png" class="img img-fluid">
      <div v-if="paymentRequired">
        <PaymentRequired></PaymentRequired>
      </div>
      <div v-else>
        <div v-if="action == 'login'">
          <Login></Login>
          <div class="text-center text-light">
            <!--<a class="pointer" v-on:click="action = 'register'">Регистрация</a>-->
            <!--<a class="pointer" v-on:click="action = 'forgot_password'">Забыли пароль?</a>-->
          </div>
        </div>
        <div v-if="action == 'register'">
          <Register></Register>
          <div class="text-center text-light">
            <a class="pointer" v-on:click="action = 'login'">Вход</a>
            <a class="pointer" v-on:click="action = 'forgot_password'">Забыли пароль?</a>
          </div>
        </div>
        <div v-if="action == 'forgot_password'">
          <ForgotPassword></ForgotPassword>
          <div class="text-center text-light">
            <a class="pointer" v-on:click="action = 'login'">Вход</a>
            <!--<a class="pointer" v-on:click="action = 'register'">Регистрация</a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from './login.vue'
import Register from './register.vue'
import ForgotPassword from './forgot_password.vue'
import PaymentRequired from './payment_required.vue'
import '../../images/logo.png';

export default {
  props: [
    'action'
  ],
  components: {
    Login,
    Register,
    ForgotPassword,
    PaymentRequired
  },
  computed: {
    paymentRequired: function(){
      return this.$store.getters.payment_required
    }
  }
}
</script>
