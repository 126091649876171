<template>
  <div class="container-fluid">
    <div class="card">
      <form class="card-body" autocomplete="off" @submit="save(item)">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <AutocompleteInput
                label="Реферал"
                resource="contact"
                :item="item"
                attribute="referrer"
              ></AutocompleteInput>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <AutocompleteInput
                label="Роль клиента в партнерской программе"
                resource="contact_role"
                :item="item"
                attribute="role"
              ></AutocompleteInput>
            </div>
          </div>
          <div class="col-sm-12">
            <FormActions :item="item"></FormActions>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  props: ['item'],
  data: function () {
    return {}
  },
  methods: {
    reload(item){
      return this.$parent.reload(item);
    },
    save(item){
      return this.$parent.save(item);
    }
  }
}
</script>

<style scoped>
</style>
