import API from './api.js'
import router from './router.js'
import localStorage from './local_storage.js'
import store from './store.js'

const paymentRequired = function(redirect = false){
  store.commit('requirePayment')
}

const signOut = function(redirect = false){
  var tokenData = localStorage.get('accessToken')
  if(tokenData && tokenData.access_token){
    API.post('/oauth/revoke.json', {
      token: tokenData.access_token
    })
    .then(function (response) {
      delete API.defaults.headers.common['Authorization']
      localStorage.remove('accessToken')
      store.commit('resetConfig')
      store.commit('setSignedIn', false)
      store.commit('resubscribe', null)
      if(redirect){
        router.push('/login')
      }
    })
  } else {
    if(redirect){
      router.push('/login')
    }
  }
}

const signedIn = function(){
  var tokenData = localStorage.get('accessToken');
  if(tokenData && tokenData.access_token){
    return true
  } else
  {
    return false
  }
}

const reloadConfig = function(callback = null){
  API.get('/config.json', {}).then(function (response) {
    var user = response.data.data
    store.commit('setConfig', user)
    store.commit('setSignedIn', true)
    store.commit('resubscribe', user.id)
    if(typeof(callback) === 'function'){
      callback()
    }


  })
  .catch(function (error) {
    console.log(error);
  })
}

const setSignedIn = function(tokenData = null, callback = null){
  if(tokenData){
    localStorage.set('accessToken', tokenData)
  } else {
    tokenData = localStorage.get('accessToken')
  }

  if (tokenData && tokenData.access_token){
    API.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData.access_token;
    reloadConfig(callback);
  } else {
    signOut();
  }
}

export default {
  signOut,
  signedIn,
  setSignedIn,
  reloadConfig,
  paymentRequired
}
