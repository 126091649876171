<template>
  <div id="nav-button" v-on:click="toggle" v-on:mouseenter="open">
    <router-link to="/dashboard" id="nav-logo" class="d-none d-md-block">
      <img :src="logo"/>
    </router-link>
    <div id="nav-icons" class="d-block d-md-none">
      <i class="zmdi zmdi-close" v-if="toggled"></i>
      <i class="zmdi zmdi-menu" v-else></i>
    </div>
  </div>
</template>

<script>
import LogoCircle from '../../images/logo-circle.png'

export default {
  data: function(){
    return {
      logo: LogoCircle
    }
  },
  methods: {
    open: function(){
      this.$store.dispatch('leftMenuToggle', true)
    },
    toggle: function(){
      this.$store.dispatch('leftMenuToggle', !this.toggled)
    }
  },
  computed: {
    toggled: function() {
      return this.$store.getters.leftMenuOpened;
    }
  }
}
</script>
