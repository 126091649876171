<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <multiselect
      v-model="item[attribute]"
      track-by="id"
      label="name"
      v-bind:placeholder="placeholder"
      :options="options"
      :searchable="true"
      :allow-empty="true">
      <template
        slot="singleLabel"
        slot-scope="{ option }">
        <i class="zmdi zmdi-alert-circle" :class="priorityColors[option.id]"></i>
        <span :class="priorityColors[option.id]">{{ option.name }}</span>
      </template>
    </multiselect>
  </component>
</template>

<script>
export default {
  name: 'EnumInput',
  props: {
    placeholder: {
      type: String,
      default: 'Выберите из списка'
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      default: [
        {
          id: 'high',
          name: 'Высокий'
        },
        {
          id: 'medium',
          name: 'Средний'
        },
        {
          id: 'low',
          name: 'Низкий'
        },
      ]
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    }
  },
  computed: {
    priorityColors(){
      return {
        'high': 'text-danger',
        'medium': 'text-warning',
        'low': 'text-success'
      }
    }
  }
}
</script>
