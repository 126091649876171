<template>
  <div>
    <div class="form-group">
      <label>Артикул</label>
        <input type="text" class="form-control" v-model="item.vendor_code">
    </div>

    <div class="form-group">
      <label>Внешний код (service_id)</label>
        <input type="text" class="form-control" v-model="item.service_id">
    </div>
    <EnumInput
      label="Наличие (статус)"
      :attribute="'selected_state'"
      :item="item"
      :options="$parent.positionStates"
      :change="$parent.selectState"
    ></EnumInput>

    <MoneyInput
      label="Цена"
      :attribute="'price_cents'"
      :item="item"
    ></MoneyInput>

    <div class="form-group">
      <label>Описание</label>
      <textarea rows="3" class="form-control" v-model="item.description"></textarea>
    </div>
    <div v-if="item.fields_config && item.fields">
      <hr>
      <div  v-for="field_config in item.fields_config">
        <component
          :is="field_config.component"
          formGroupComponent="FormGroupRow"
          :label="field_config.name"
          :item="item.fields"
          :attribute="field_config.code"
          resource="position"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'item'
  ],
  data: function () {
    return {}
  }
}
</script>
