<template>
  <div class="settings container">

    <div class="row">
      <div class="col-12">
        <div class="card mt-3 mb-3">
          <div class="card-header"><h5 >Персональные настройки.</h5></div>
          <b-list-group class="list-group-flush">
            <b-list-group-item to="/settings/profile">Личный профиль</b-list-group-item>
            <b-list-group-item to="/settings/user_notifications">Мои уведомления</b-list-group-item>
            <b-list-group-item to="/settings/appearance">Внешний вид</b-list-group-item>
          </b-list-group>
        </div>

        <div class="card mb-3" v-if="superuser">
          <div class="card-header"><h5 >Общие настройки.</h5></div>
          <b-list-group>
            <b-list-group-item v-if="superuser" to="/settings/contacts">Контакты: управление черным списком, др.</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/groups/roots">Группы: иерархия</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/roles/roots">Роли</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/contact_roles/roots">Роли клиентов</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/integrations">Интеграции: настройка, проверка состояния</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/orders">Заказы: настройка статусов</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/tasks">Задачи: настройка видов, статусов</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/positions/roots">Каталог товаров (старый)</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/sites">Сайты: подключение, изменение настроек</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/users">Пользователи: управление, приглашение новых</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/activities">Пользовательские действия</b-list-group-item>
          </b-list-group>
        </div>

        <div class="card mb-3">
          <div class="card-header"><h5 >Автоматизация.</h5></div>
          <b-list-group>
            <b-list-group-item v-if="superuser" to="/templates">Шаблоны писем, SMS, сообщений в социальных сетях</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/entities">Cправочники</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/triggers">Триггеры</b-list-group-item>
            <b-list-group-item v-if="superuser" to="/settings/notifications">Уведомления</b-list-group-item>
            <b-list-group-item to="/positions">Каталог товаров</b-list-group-item>
            <b-list-group-item to="/webhooks">Веб-хуки</b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  computed: {
    superuser: function(){
      return this.$store.getters.super
    },
  }
}
</script>

<style scoped>
</style>
