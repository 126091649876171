import store from './store.js'

const mixin = {
  methods: {
    toggleFilters: function(){
      this.$store.dispatch('rightMenuToggle', !this.filtersEnabled)
    }
  },
  computed: {
    filtersEnabled: function() {
      return this.$store.getters.rightMenuOpened;
    }
  }
}

export default mixin
