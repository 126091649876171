<template>
  <div class="tasks">
    <Header>
      <li class="float-left">
        <router-link to="/tasks/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
          <span class="nav-title">Добавить задачу</span>
        </router-link>
      </li>

      <li v-bind:class="{active: filtersEnabled}" class="float-right">
        <a v-on:click="toggleFilters">
          <i class="tm-icon zmdi zmdi-filter-list"></i>
          <span class="nav-title">Фильтры</span>
        </a>
      </li>
      <li class="float-right">
        <a>
          <i class="tm-icon zmdi zmdi-check-all"></i>
          <span class="nav-title">Групповые действия</span>
        </a>
      </li>
    </Header>


    <NavbarRight>
      <form @submit="search">
      <div class="card mb-5 pb-5">
        <div class="card-body">

            <br>
            <br>

            <AutocompleteInput
              :multiple="true"
              label="Вид задачи"
              attribute="kind_id_in"
              :item="f"
              :resource="'task_kind'"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Статус задачи"
              attribute="state_id_in"
              :item="f"
              resource="task_state"
            ></AutocompleteInput>

            <PeriodInput
              label="Дата открытия"
              attribute="service_created_at"
              :item="f"
            ></PeriodInput>

            <PeriodInput
              label="Дедлайн"
              attribute="deadline_at"
              :item="f"
            ></PeriodInput>

            <AutocompleteInput
              :multiple="true"
              label="Клиент"
              :item="f"
              attribute="contact_id_in"
              resource="contact"
            ></AutocompleteInput>

            <AutocompleteInput
              :multiple="true"
              label="Ответственный"
              :item="f"
              attribute="user_id_in"
              :resource="'user'"
            ></AutocompleteInput>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-success btn-block btn-lg btn-fixed-bottom"
        >
          <i class="zmdi zmdi-search"></i>
          Найти
        </button>
      </form>
    </NavbarRight>

    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="listview lv-bordered">
              <div class="lv-header-alt clearfix">
                <h2 class="lvh-label hidden-xs">Some text here</h2>
                <div class="lvh-search">
                  <input
                    type="text"
                    placeholder="Начните печатать для поиска..."
                    class="lvhs-input"
                    v-model="searchString"
                    @keyup="syncSearchString"
                  />
                  <i class="lvh-search-close">&times;</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="container-fluid mb-2">
      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            разобрать
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card">
            сделать
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card">
            наблюдаемые
          </div>
        </div>
      </div>
    </div>-->

    <Rows></Rows>
    <br>
    <br>
    <br>
    <br>

    <infinite-loading
      @infinite="infiniteItems"
      :identifier="infiniteId"
    ></infinite-loading>
  </div>
</template>

<script>
import Rows from './views/rows'

import NavbarRight from '../shared/navbar-right.vue'

import FiltersMixin from '../../services/filters_mixin'
import CollectionMixin from '../../services/collection_mixin'
import ViewsMixin from '../../services/views_mixin'

export default {
  mixins: [CollectionMixin, FiltersMixin, ViewsMixin],
  components: {
    Rows,
    NavbarRight
  },
  data: function(){
    return {
      resourceName: 'task',
      searchString: ''
    }
  }, created: function(){
    var self = this
    self.syncSearchString = self._.debounce(function(e){
      self.setSearchString(self.searchString)
    },800)
  }
}
</script>
