import App from './app.vue'
import Navbar from './shared/navbar.vue'
import Footer from './shared/footer.vue'
import Dashboard from './dashboard/index.vue'
import Login from './auth/login.vue'
import Register from './auth/register.vue'

import Contacts from './contacts/index.vue'
import Contact from './contacts/show.vue'

import Orders from './orders/index.vue'
import Order from './orders/show.vue'

import Conversations from './conversations/index.vue'
import Conversation from './conversations/show.vue'

import Tasks from './tasks/index.vue'
import Task from './tasks/show.vue'

import Jobs from './jobs/index.vue'
import Job from './jobs/show.vue'

import Entities from './entities/index.vue'
import Entity from './entities/show.vue'

import Items from './items/index.vue'
import Item from './items/show.vue'

import Users from './users/index.vue'
import User from './users/show.vue'

import Webhooks from './webhooks/index.vue'
import Notifications from './notifications/index.vue'

import Templates from './templates/index.vue'
import Template from './templates/show.vue'

import Positions from './positions/index.vue'
import Position from './positions/show.vue'

import Settings from './settings/index.vue'
import SettingsContacts from './settings/contacts.vue'
import SettingsGroups from './settings/groups.vue'
import SettingsRoles from './settings/roles.vue'
import SettingsContactRoles from './settings/contact_roles.vue'
import SettingsIntegrations from './settings/integrations.vue'
import SettingsOrders from './settings/orders.vue'
import SettingsTasks from './settings/tasks.vue'
import SettingsPositions from './settings/positions.vue'
import SettingsProfile from './settings/profile.vue'
import SettingsSites from './settings/sites.vue'
import SettingsTriggers from './settings/triggers.vue'
import SettingsBecome from './settings/become.vue'
import SettingsActivities from './settings/activities.vue'
import SettingsUserNotifications from './settings/user/notifications.vue'
import SettingsCompanyNotifications from './settings/company/notifications.vue'
import SettingsDocumentCategories from './settings/document_categories.vue'
import SettingsProcedures from './settings/procedures.vue'
import SettingsAppearance from './settings/appearance.vue'

import WikiSpaces from './wiki/spaces/index.vue'
import WikiPages from './wiki/pages/index.vue'


export default {
  App,
  Footer,
  Navbar,
  Dashboard,
  Login,
  Register,
  Conversations,
  Conversation,
  Contacts,
  Contact,
  Orders,
  Order,
  Tasks,
  Task,
  Jobs,
  Job,
  Entities,
  Entity,
  Items,
  Item,
  Users,
  User,
  Notifications,
  Webhooks,
  Templates,
  Template,
  Positions,
  Position,
  Settings,
  SettingsContacts,
  SettingsGroups,
  SettingsRoles,
  SettingsContactRoles,
  SettingsIntegrations,
  SettingsOrders,
  SettingsTasks,
  SettingsPositions,
  SettingsProfile,
  SettingsSites,
  SettingsTriggers,
  SettingsBecome,
  SettingsActivities,
  SettingsUserNotifications,
  SettingsCompanyNotifications,
  SettingsDocumentCategories,
  SettingsProcedures,
  SettingsAppearance,
  WikiSpaces,
  WikiPages
}
