import bg1 from '../images/bg/bg1.jpg'
import bg2 from '../images/bg/bg2.jpg'
import bg3 from '../images/bg/bg3.jpg'
import bg4 from '../images/bg/bg4.jpg'
import bg5 from '../images/bg/bg5.jpg'
import bg6 from '../images/bg/bg6.jpg'
import bg7 from '../images/bg/bg7.jpg'
import bg8 from '../images/bg/bg8.jpg'
import bg9 from '../images/bg/bg9.jpg'

const defaultBackgrounds = [
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7,
  bg8,
  bg9,
]

export default defaultBackgrounds
