<template>
  <div>
    <Header search="true"></Header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 pt-3">
          <div class="card mb-2" v-for="item in items">
            <div class="card-body">
              <div class="float-right">
                <b>{{item.created_at | moment("YYYY.MM.DD HH:mm:ss")}} ({{item.created_at | moment("from", "now") }})</b>
              </div>
              <h4>
                <span>{{item.request_method}} {{item.request_uri}} </span>
                <br>
                <small>{{item.integration && item.integration.name}}</small>
              </h4>
              <p>
                ID: {{item.request_id}}<br>
                Статус: {{item.state}}<br>
                <button v-if="item.state != 'success'" v-on:click="rerun(item)" class="btn btn-sm btn-warning"><i class="zmdi zmdi-refresh"></i></button><br>
              </p>
              <codemirror v-model="item.body"/>
              <div v-if="item.procedure_call">
                <p>ошибки: <span v-for="failure in item.procedure_call.failures">{{failure}}</span></p>
                <p>
                  лог: <br>
                  <span>{{item.procedure_call.logs}}</span>
                </p>
              </div>
            </div>
          </div>
          <infinite-loading
            @infinite="infiniteItems"
            :identifier="infiniteId"
          ></infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [
    CollectionMixin
  ],
  components: {
  },
  data: function(){
    return {
      resourceName: 'webhook'
    }
  },
  methods: {
    rerun: function(item){
      this.save(item); // updating == rerun
    }
  }
}
</script>
