<template>
  <div>
    <Header search="true">
      <li class="float-left">
        <router-link to="/users/new">
          <i class="tm-icon zmdi zmdi-plus-circle"></i>
          <span class="nav-title">Пригласить</span>
        </router-link>
      </li>
    </Header>

    <div class="container-fluid">
      <!--<div class="row mb-2">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h6>
                Пригласите коллег с помощью <b>магической ссылки!</b>
                <br>
                <small>Каждый сможет сам зарегистрироваться в системе</small>
              </h6>
            </div>
          </div>
        </div>
      </div>-->
      

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="table-responsive">
              <table class="table table-sm table-hover">
                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">ФИО</th>
                    <th scope="col">Статус</th>
                    <th scope="col">Последний вход</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items">
                    <td><router-link :to="{ name: 'user', params: {id: item.id} }">{{item.email}}</router-link></td>
                    <td>{{item.name}}</td>
                    <td>{{item.state}}</td>
                    <td>{{ item.last_sign_in_at | moment("from", "now") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <infinite-loading
              @infinite="infiniteItems"
              :identifier="infiniteId"
            ></infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'

export default {
  mixins: [CollectionMixin],
  data: function(){
    return {
      resourceName: 'user'
    }
  }
}
</script>

<style scoped>
</style>
