<template>
  <div class="messages">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div ref="scrollcontainer" style="max-height:60vh;overflow:auto;">
                <infinite-loading
                  direction="top"
                  @infinite="infiniteItems"
                  :identifier="infiniteId"
                >
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>

                <div v-for="(item, $index) in reversedItems" :key="$index">
                  <component :is="item.entity" :item="item"></component>
                </div>
              </div>
              <div class="pb-3" style="display:none;">
                <hr>
                <textarea rows="2" class="form-control"></textarea>
                <div class="text-center mt-3 text-light">
                  <a class="btn btn-success pointer">
                    <i class="zmdi zmdi-mail-send"></i>
                    Отправить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionMixin from '../../services/collection_mixin'
import event from './event'
import message from './message'

export default {
  mixins: [
    CollectionMixin
  ],
  components: {
    event,
    message
  },
  computed: {
    reversedItems: function(){
      return this.items.reverse()
    }
  },
  methods: {
    afterFirstLoad(){
      var element = this.$refs.scrollcontainer
      element.scrollTop = element.scrollHeight
    },
  },
  data: function(){
    return {
      resourceName: 'log',
      skipLoadConfig: true
    }
  }
}
</script>
