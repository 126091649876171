<template>
  <component :is="formGroupComponent" :label="label" :item="item" :attribute="attribute">
    <multiselect
      :multiple="true"
      v-model="item[attribute]"
      track-by="id"
      label="name"
      :placeholder="placeholder"
      :searchable="true"
      :internal-search="false"
      :allow-empty="true"
      :options="findedItems"
      :loading="loading"
      :taggable="true"
      @open="findItems"
      @tag="addTag"
      @select="selectTags"
      @remove="nullifyOrChange"
      @search-change="findItems"
    >
      <template
        slot="singleLabel"
        slot-scope="{ findedItem }">
        <strong v-if="findedItem">{{ findedItem.name }}</strong>
      </template>
    </multiselect>
  </component>
</template>

<script>
import Resources from '../../services/resources'

export default {
  name: 'TagsInput',
  props: {
    resource: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Начните печатать'
    },
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    },
    formGroupComponent: {
      type: String,
      required: false,
      default: 'FormGroup'
    },
    nullify: {
      type: Function
    }
  },
  methods: {
    selectTags: function(selected){},
    addTag: function(tagName){
      var self = this

      Resources.tag.create({
        name: tagName,
        entity_type: self.resource
      }).then(function(response){
        self.findedItems.push(response.data.data)
        self.item[self.attribute].push(response.data.data)
      })
    },
    findItems: function(searchString){
      var self = this

      if(searchString != ''){
        self.loading = true

        Resources.tag.index({
          s: searchString,
          q: { entity_type_eq: self.resource }
        }).then(function(response){
          self.findedItems = response.data.data
          self.loading = false
        }).catch(function(response){
          self.loading = false
        })
      }
    },
    nullifyOrChange: function(){
      this.nullify || this.change
    }
  },
  data: function(){
    return {
      loading: false,
      findedItems: []
    }
  }
}
</script>
