<template>
  <div class="card-body" v-if="item">
    <div class="row">
      <div class="col col-md-6">
        <AutocompleteInput
          formGroupComponent="FormGroup"
          label="Тип"
          :item="item"
          attribute="kind"
          :disabled="true"
          resource="order_kind"
        ></AutocompleteInput>

        <AutocompleteInput
          formGroupComponent="FormGroup"
          label="Статус"
          :item="item"
          attribute="state"
          :disabled="true"
          resource="order_state"
        ></AutocompleteInput>
      </div>
      <div class="col col-md-6">
        <AutocompleteInput
          formGroupComponent="FormGroup"
          label="Группа"
          :item="item"
          attribute="group"
          resource="group"
        ></AutocompleteInput>

        <AutocompleteInput
          formGroupComponent="FormGroup"
          label="Ответственный"
          :item="item"
          attribute="user"
          resource="user"
        ></AutocompleteInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TagsInput
          formGroupComponent="FormGroup"
          label="Теги"
          :item="item"
          attribute="tags"
          resource="order"
        >
        </TagsInput>
      </div>
    </div>




    <div v-if="item.fields_config && item.fields">
      <hr>
      <div v-bind:key="field_config.code" v-for="field_config in item.fields_config">
        <component
          :is="field_config.component"
          :componentParams="field_config.component_params"
          :multiple="field_config.multiple"
          formGroupComponent="FormGroup"
          :label="field_config.name"
          :item="item.fields"
          :attribute="field_config.code"
          :resource="field_config.component_params.resource"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderForm',
  props: ['item']
}
</script>
