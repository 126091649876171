<template>
  <div>
    <hr>
    <div class="form-group text-center" v-if="item">
      <button
        class="btn btn-light"
        :disabled="item._saving || item._reloading"
        @click.prevent="reload"
      >
        <i class="zmdi zmdi-refresh" :class="{'zmdi-hc-spin': item._reloading}"></i>
      </button>

      <button
        :disabled="item._saving"
        @click.prevent="save"
        class="btn btn-success"
        type="submit"
      >
        <i class="zmdi zmdi-spinner zmdi-hc-spin" v-if="item._saving"></i>
        Сохранить
      </button>

      <b-dropdown
        v-if="item.state && item.state.name"
        :text="item.state.name"
        variant="custom"
        v-bind:style="'background-color:'+(item.state.color && item.state.color.replace('0.35', '1'))"
      >
        <b-dropdown-item v-for="transition in item.transitions" @click="transite(transition)">
          {{transition.name}}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormActions',
  props: {
    item: {
      required: true
    }
  },
  methods: {
    transite(transition){
      this.item.transition_id = transition.code
      this.save()
    },
    save(){
      this.parent.save(this.item);
    },
    reload(){
      this.parent.reload(this.item);
    }
  },
  computed: {
    parent: function(){
      if(this.$parent.save){
        return this.$parent;
      } else if(this.$parent.$parent.save){
        return this.$parent.$parent;
      } else if(this.$parent.$parent.$parent.save){
        return this.$parent.$parent.$parent;
      }
    }
  },
  data: function(){
    return {}
  }
}
</script>
