<template>
  <div class="form-group">
    <label class="mb-0 text-muted" v-bind:class="{'text-danger': errors && errors.length > 0}" v-html="label"></label>
    <slot></slot>
    <small class="help-block text-danger" v-for="error in errors">
      {{error}}<br>
    </small>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  data: function(){
    return {}
  },
  computed: {
    errors: function(){
      return this.item && this.item.errors && this.item.errors[this.attribute];
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    item: {},
    attribute: {
      type: String,
      required: true
    }
  }
}
</script>
