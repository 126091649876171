import Vue from 'vue'
import Vuex from 'vuex'
import Subscribe from '../services/subscribe.js'
import CollectionStore from './collection_store.js'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loaded: false,
    user: null,
    company: null,
    data: null,
    subscription: null,
    additionalSubscription: null,
    unreadNotificationsCount: 0,
    RIGHT_MENU_OPENED: false,
    LEFT_MENU_OPENED: true,
    PAYMENT_REQUIRED: false
  },
  modules: {
    collection: CollectionStore
  },
  actions: {
    leftMenuToggle ({ commit }, openStatus) {
      commit('leftMenuToggle', openStatus)
    },
    rightMenuToggle ({ commit }, openStatus) {
      commit('rightMenuToggle', openStatus)
    }
  },
  mutations: {
    resubscribe: function(state, id){
      state.subscription = Subscribe(state.subscription, id)
    },
    additionalResubscribe: function(state, id){
      state.additionalSubscription = Subscribe(state.additionalSubscription, id)
    },
    setLoaded: function(state, loadingState) {
      state.loaded = loadingState
    },
    setConfig: function(state, user_with_config){
      state.user = user_with_config
    },
    setSignedIn: function(state, signed_in){
      state.signed_in = signed_in
    },
    resetConfig: function(state){
      state.user = null
    },
    leftMenuToggle: function(state, openStatus){
      if (openStatus === 'undefined'){
        state.LEFT_MENU_OPENED = !state.LEFT_MENU_OPENED
      } else {
        state.LEFT_MENU_OPENED = openStatus
      }
    },
    rightMenuToggle: function(state, openStatus){
      if (openStatus === 'undefined'){
        state.RIGHT_MENU_OPENED = !state.RIGHT_MENU_OPENED
      } else {
        state.RIGHT_MENU_OPENED = openStatus
      }
    },
    receiveNotification: function(state, notificationData){
      state.unreadNotificationsCount = notificationData.unread_count || 0
    },
    requirePayment: function(state){
      state.PAYMENT_REQUIRED = true
    }
  },
  getters: {
    loaded: function(state){
      return state.loaded
    },
    modules: function(state){
      if (state.user && state.user.company && state.user.company.modules){
        return state.user.company.modules
      } else {
        return {}
      }
    },
    company: function(state){
      if (state.user && state.user.company){
        return state.user.company
      } else {
        return {}
      }
    },
    user: function(state){
      if (state.user){
        return state.user
      } else {
        return {}
      }
    },
    super: function(state){
      if (state.user && state.user.super){
        return true;
      } else {
        return false
      }
    },
    signed_in: function(state){
      return state.signed_in
    },
    payment_required: function(state){
      return state.PAYMENT_REQUIRED
    },
    data: function(state){
      if (state.data){
        return state.data
      } else {
        return {}
      }
    },
    rightMenuOpened: function(state){
      return state.RIGHT_MENU_OPENED;
    },
    leftMenuOpened: function(state){
      return state.LEFT_MENU_OPENED;
    },
    unreadNotificationsCount: function(state){
      return state.unreadNotificationsCount;
    }
  }
})

export default store
