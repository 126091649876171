<template>
  <div class="card">
    <div class="card-body card-padding">
      <p class="text-center mt-3">
        Кажется, у вас не оплачен доступ. Свяжитесь с вашим менеджером, чтобы продлить доступ.
      </p>
      <div class="text-center">
        <a @click="refresh" class="btn btn-success btn-block mt-4 waves-effect pointer text-light">
          <span>Обновить</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    refresh(){
      window.location.reload()
    }
  }
}
</script>
