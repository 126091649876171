<template>
  <div>123</div>
</template>
<script>
import ItemMixin from '../../services/item_mixin'

export default {
  data: function() {
    return {
      resourceName: 'contact'
    }
  },
  mixins: [
    ItemMixin
  ],
  components: {
  }
}
</script>
