<template>
  <div class="container-fluid inherit-link-styles">
    <div class="row">
      <div class="col">
        <div v-for="item in items" :key="item.id">
          <Row :item="item"></Row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Row from './row'

export default {
  computed: {
    items: function(){
      return this.$parent.items || []
    },
    filtersEnabled: function(){
      return this.$parent.filtersEnabled
    }
  },
  components: {
    Row
  },
  methods: {
    save(item){
      this.$parent.save(item)
    }
  }
}
</script>
