import axios from 'axios'
import EventBus from './event_bus'
import currentUser from './current_user'
import { deserialise } from 'kitsu-core'

const API = axios.create({
  baseURL: '/api/v2',
  timeout: 30000
});

API.interceptors.response.use(function (response) {
  if(response.data){
    deserialise(response.data)
  }
  return response;
});

API.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    currentUser.signOut()
  }

  if (error.response && error.response.status === 402) {
    currentUser.paymentRequired()
  }

  return Promise.reject(error.response);
});

API.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error && error.message && error.message.match("Network Error")){
    EventBus.$emit('notice', {
      title: 'Кажется, нет подключения к серверу',
      text: 'Проверьте соединение с сетью интернет. Если все в порядке, свяжитесь с нами.',
      variant: 'danger'
    })
  }

  if(!error){
    EventBus.$emit('notice', {
      title: 'Кажется, что-то пошло не так..',
      text: 'Наши специалисты уже получили ошибку и разберутся с ней.',
      variant: 'warning'
    })
  }
  return Promise.reject(error);
});

export default API
