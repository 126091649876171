<template>
  <div class="card-body" v-if="item" :rerender="rerenderCount">
    <div class="table-responsive">
      <table class="table table-hover table-sm text-center">
        <thead>
          <tr>
            <th class="text-left">Название</th>
            <th>Цена</th>
            <th>Кол&#8209;во</th>
            <th v-if="total_weight_cents">Вес</th>
            <th>Стоимость</th>
            <th><span @click="removeAllOrderPositions" class="zmdi zmdi-close"></span></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order_position in item.order_positions"
            v-bind:key="order_position.id"
            v-if="!order_position._destroy"
          >
            <td class="text-left pointer" @click="updateOrderPositionName(order_position)">
              {{order_position.name}}
            </td>
            <td>{{order_position.price_cents | money}}</td>
            <td>
              <span
                v-if="order_position.editable_quantity_cents"
                class="zmdi zmdi-minus"
                v-on:click="removeQuantityForOrderPosition(order_position, order_position.position.quantity_cents)">
              </span>
              {{order_position.quantity_cents}}
              <span
                v-if="order_position.editable_quantity_cents"
                class="zmdi zmdi-plus"
                v-on:click="addQuantityForOrderPosition(order_position, order_position.position.quantity_cents)">
              </span>
            </td>
            <td v-if="total_weight_cents">
              <span @click="updateOrderPositionWeight(order_position)">{{order_position.weight_cents | weight}}</span>
            </td>
            <td>{{order_position.amount_cents | money}}</td>
            <td>
               <span
                 v-if="order_position.editable_quantity_cents"
                 class="zmdi zmdi-close"
                 v-on:click="removeOrderPositionFromOrder(order_position)">
               </span>
             </td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="border-top: 2px solid #dee2e6;">
            <th class="text-left" colspan="2">Итого:</th>
            <th>{{total_quantity_cents}}</th>
            <th v-if="total_weight_cents">{{total_weight_cents | weight}}</th>
            <th>{{total_amount_cents | money}}</th>
            <th></th>
          </tr>
        </tfoot>

      </table>
    </div>

    <SelectPositions
      v-on:position-selected="addPositionToOrder"
    ></SelectPositions>
  </div>
</template>

<script>
import SelectPositions from '../../shared/select_positions'
import OrderPositionsMixin from '../../../services/order_positions_mixin'

export default {
  name: 'PositionsForm',
  props: ['item'],
  components: {
    SelectPositions
  },
  mixins: [
    OrderPositionsMixin
  ],
  computed: {
    total_quantity_cents(){
      var value = 0
      this._.each(this.item.order_positions, function(op){
        if (op.quantity_cents && op.quantity_cents > 0){
          value += op.quantity_cents
        }
      })
      return value;
    },
    total_weight_cents(){
      var value = 0
      this._.each(this.item.order_positions, function(op){
        if (op.weight_cents && op.weight_cents > 0){
          value += parseFloat(op.weight_cents)
        }
      })
      return value;
    },
    total_amount_cents(){
      var value = 0
      this._.each(this.item.order_positions, function(op){
        if (op.amount_cents && op.amount_cents > 0){
          value += op.amount_cents
        }
      })
      return value;
    }
  },
  methods: {}
}
</script>
