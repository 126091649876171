<template>
  <div class="card-body">
    <div class="table-responsive" v-if="item && item.payments && item.payments.length > 0">
      <table class="table table-hover table-sm text-center">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Вид</th>
            <th>Статус</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in item.payments">
            <td>{{payment.paid_at | moment("MM.DD.YYYY, hh:mm") }}</td>
            <td>{{payment.amount_cents | money}}</td>
            <td>{{payment.kind && payment.kind.name}}</td>
            <td>{{payment.state && payment.state.name}}</td>
            <td>
              <span @click="destroyPayment(payment)" class="zmdi zmdi-close pointer"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="item && item.id" class="text-center">
      <div v-if="newPaymentStep == 'kind'">
        <div v-if="availablePaymentKindsLoaded">
          <div v-if="availablePaymentKinds && availablePaymentKinds.length > 0">
            <a @click="setPaymentKind(paymentKind)" class="btn btn-success text-light ml-1 mr-1 pointer" v-for="paymentKind in availablePaymentKinds" >
              {{paymentKind.name}}
            </a>
          </div>
          <div v-else>
            <p>Нет доступных видов платежей. Добаввьте их в настройках</p>
          </div>
        </div>
        <div v-else>
          <a class="btn btn-success text-light pointer" @click="loadAvailablePaymentKinds">добавить платеж</a>
        </div>
      </div>
      <div v-if="newPaymentStep == 'full_or_partial'">
        <a @click="setNewPaymentStep('kind')" class="btn btn-light pointer mr-1 mt-1">
          <i class="zmdi zmdi-arrow-left"></i>
        </a>
        <a @click="payFully" class="btn btn-success text-light pointer mr-1 mt-1">
          оплатить полностью ({{item.amount_cents | money}})
        </a>
        <a @click="payPartially" class="btn btn-success text-light pointer mr-1 mt-1">
          оплатить частично
        </a>
      </div>
      <div v-if="newPaymentStep == 'amount_cents'">
        <div class="row">
          <div class="col-md-3 offset-md-3 col-sm-4 offset-sm-2">
            <input
              v-model="newPayment.amount_cents"
              placeholder="Укажите сумму платежа"
              type="text"
              class="form-control mt-1"
            />
          </div>
          <div class="col-md-3 col-sm-4">
            <a @click="savePayPartially" class="btn btn-success btn-block text-light pointer mr-1 mt-1">
              Сохранить
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      Перед добавлением платежей, пожалуйста, сохраните заказ
    </div>
  </div>
</template>

<script>
import Resources from '../../../services/resources'

export default {
  name: 'PaymentsForm',
  props: ['item'],
  data: function(){
    return {
      newPaymentStep: 'kind',
      newPayment: {
        kind: null,
        amount_cents: null
      },
      availablePaymentKinds: [],
      availablePaymentKindsLoaded: false
    }
  },
  methods: {
    setNewPaymentStep(stepName){
      this.newPaymentStep = stepName
    },
    payFully(){
      this.newPayment.amount_cents = this.item.amount_cents
      this.savePayment()
    },
    payPartially(){
      this.newPaymentStep = 'amount_cents'
    },
    savePayPartially(){
      this.savePayment()
    },
    setPaymentKind(paymentKind){
      this.newPayment.kind = paymentKind
      this.setNewPaymentStep('full_or_partial')
    },
    loadAvailablePaymentKinds(){
      var self = this

      Resources.payment_kind.index({}).then(function(response){
        self.availablePaymentKinds = response.data.data
        self.availablePaymentKindsLoaded = true
      })
    },
    savePayment(){
      var self = this
      self.newPayment.order_id = self.item.id
      Resources.payment.create(self.newPayment).then(function(response){
        self.item.payments.push(response.data.data)
        self._.each(self.newPayment, function(v,k){
          self.newPayment[k] = null
        })
        self.newPaymentStep = 'kind'
      })
    },
    destroyPayment(payment){
      var self = this
      Resources.payment.destroy(payment.id).then(function(response){
        self.item.payments.splice(self.item.payments.indexOf(payment), 1);
      })
    }
  },
  computed: {
    payments(){
      return this.item.payments || []
    }
  }
}
</script>
