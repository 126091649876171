const mixin = {
  methods: {
    isActiveView: function(view){
      return this.currentView === view
    },
    toggleView: function(view){
      this.currentView = view
    }
  }
}

export default mixin
