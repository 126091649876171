<template>
  <pie-chart :data="data" :legend="legend"></pie-chart>
</template>

<script>
export default {
  name: 'PieChartWidget',
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    legend: {
      type: Boolean
    }
  }
}
</script>
