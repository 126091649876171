<template>
  <span class="badge badge-pill badge-light" :class="className" @click="changePriority">
    <i class="zmdi zmdi-alert-circle" v-bind:class="priorityColor"></i>
  </span>
</template>

<script>
export default {
  name: 'PriorityBadge',
  props: {
    attribute: {
      type: String,
    },
    item: {}
  },
  data: function(){
    return {}
  },
  methods: {
    changePriority: function(){
      switch (this.item.priority){
        case 'high':
          this.item.priority = 'low';
        break;
        case 'medium':
          this.item.priority = 'high';
        break;
        case 'low':
          this.item.priority = 'medium';
        break;
      }

      this.$parent.save(this.item)
    }
  },
  computed: {
    className: function(){
      return this.class
    },
    priorityColor: function(){
      switch (this.item.priority){
        case 'high':
          return 'text-danger';
        break;
        case 'medium':
          return 'text-warning';
        break;
        case 'low':
          return 'text-success';
        break;
      }
    }
  }
}
</script>
